import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { cartSelectors } from './cart.selectors';
import { cartActions } from './cart.actions';
import { CartState } from './cart.interface';
import { AddItemRequest, UpdateItemRequest } from '../models';
import { CartItem, ShipMethod } from '../models/cart.model';

@Injectable()
export class CartFacade {
  cart$ = this.store.select(cartSelectors.getCart);
  page$ = this.store.select(cartSelectors.getItems);
  isLoading$ = this.store.select(cartSelectors.isLoading);
  isLoaded$ = this.store.select(cartSelectors.isLoaded);

  constructor(private store: Store<CartState>) {}

  init() {
    this.store.dispatch(cartActions.init());
  }

  find(customId: string) {
    this.store.dispatch(cartActions.find({ customId }));
  }

  removeItem(cartId: string, lineItemId: number) {
    this.store.dispatch(cartActions.removeProduct({ cartId, lineItemId }));
  }

  applyPromoCode(cartId: string, promoCode: string){
    this.store.dispatch(cartActions.applyPromoCode({ cartId, promoCode }));
  }
  removePromoCode(cartId: string, promoCode: string){
    this.store.dispatch(cartActions.removePromoCode({ cartId, promoCode }));
  }
  addItem(item: AddItemRequest) {
    this.store.dispatch(cartActions.addProduct(item));
  }

  updateItem(cartId: string, request: UpdateItemRequest) {
    this.store.dispatch(cartActions.updateProduct({ cartId, request }));
  }
addItemsToUserCart(cartId : string, customId : string){
  this.store.dispatch(cartActions.loginWithCartItems({ cartId: cartId, customId: customId}));
}

  setAddressToItem(
    cartId: string,
    address: any,
    shipMethod: ShipMethod,
    items: CartItem[]
  ) {
    this.store.dispatch(
      cartActions.updateItemShippingMethod({
        cartId,
        address,
        shipMethod,
        items,
      })
    );
  }
}
