import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface CartStep {
  status?: string; //done,active,disabled
  btnText?: string;
  styleClass?: string;
  icon?: {
    type: string; //icon or img
    name: string;
    class?: string;
    alt?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class CartStepService {
  private dataSubject = new BehaviorSubject<CartStep>({});
  currentStep$ = this.dataSubject.asObservable();

  constructor() {}

  updateStep(step: CartStep) {
    this.dataSubject.next(step);
  }
}
