<section>
  <div class="container">
    <div class="row">
      <ng-container *ngIf="cart$ | async as cart">
        <div
          *ngIf="cart.totalItems > 0 || (isLoading$ | async) === true"
          class="col-lg-8"
        >
    
          <div class="container">
            <!-- <ng-container *ngIf="cart$ | async as cart ">
              <ng-container *ngIf="cart.totalItems > 0 && cart.allPromosApplied == null">
                  <div
                    class="total-card mobile-view mobile"
                  >
                    <div class="row ps-2 pe-2 cart-totals">
                    
                   <label><strong><span>Apply a promotion</span></strong></label>
                   <input class="input-text" type="text" placeholder="Promotion code or coupon" [(ngModel)]="promo">
                    </div>
                    <div class="row ps-3 pe-3 mt-4">
                      <button
                        (click)="applyPromoCode(cart)"
                        class="ea-button ea-button-primary  w-100 p-1 font-title font-bold"
                        [disabled]="!promo || (isButtonsDisabled$ | async)"
                      >
                       Apply
                      </button>
                    </div>
                  </div>
             </ng-container>
             </ng-container> -->
    
          <ea-order-steps [step]="'Bag'" class="mb-4"></ea-order-steps>
          <!-- <div class=" col-lg-12 mt-4 mobile"   style="min-width: 300px !important;">
            <ea-toast 
            style="min-width: 300px !important;"
            *ngIf=" this.cartService.toast"
            [toast]=" this.cartService.toast"
            (dispose) ="closeToast()"
            ></ea-toast>
          </div> -->
     
          </div>
        </div>
        <div class="col-lg-8 mb-5">
          <div class="">
            <div *ngIf="(isLoading$ | async) === true" class="singleton">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{
                      'height.px': 100,
                      'background-color': '#e4e4e4'
                    }"
                    [animation]="false"
                  ></ngx-skeleton-loader>
                </div>
                <div class="col-md-6 col-lg-6">
                  <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                      'height.px': 25,
                      'background-color': '#e4e4e4'
                    }"
                    [animation]="false"
                  ></ngx-skeleton-loader>
                </div>
              </div>
            </div>

            <!-- start card item -->
            <div class="row ps-3 mt-md-4">
              <ng-container
                *ngIf="
                  cart.totalItems > 0 || (isLoading$ | async) === true;
                  else empty
                "
              >
                <div
                  *ngFor="let item of cart.items; let i = index"
                  class="item-cart"
                >
                  <!-- Mobile View (with image above title) -->
                  <div class="mobile-view d-block d-md-none">
                    <div class="item-title">
                      <a
                        class="cart-item-name"
                        [routerLink]="[
                          '/product-details',
                          item.extra.parentName.description
                        ]"
                        [title]="item.title"
                      >
                        {{ item.extra.parentName.value }}
                      </a>
                    </div>
                    <div class="row mb-2">
                      <div class="col-5">
                        <!-- Product Image -->
                        <img
                          class="cart-item-image"
                          alt="{{ item | attribute: 'image_alt_main' }}"
                          src="{{
                            item
                              | attribute: 'image_main_product'
                              | getImageFilter
                          }}"
                        />
                      </div>
                      <div class="col-7 mt-2">
                        <div class="justify-content-between row">
                          <div class="col-8">
                            <span
                              *ngIf="item.title != item.extra.parentName.value"
                              [innerHTML]="getItemTitle(item)"
                            ></span>
                          </div>

                          <div class="col-4">
                            <ng-container
                              *ngIf="item.unitPrice.discount?.discountAmount"
                            >
                              <div
                                class="base-price line-through text-gray-500 font-title"
                              >
                                {{
                                  item.unitPrice.amount
                                    | currency: item.unitPrice.currency
                                }}
                              </div>
                            </ng-container>
                            <div
                              class="font-title font-bold"
                              *ngIf="
                                item.unitPrice.sale ==
                                item.extra.orginalPrice.price
                              "
                            >
                              {{
                                item.unitPrice.sale
                                  | currency: item.unitPrice.currency
                              }}
                            </div>
                            <div
                              class="font-title t font-bold"
                              *ngIf="
                                item.unitPrice.sale !=
                                item.extra.orginalPrice.price
                              "
                            >
                              <span Class="old-price ">{{
                                item.extra.orginalPrice.price
                                  | currency: item.unitPrice.currency
                              }}</span>
                              <br />
                              <span
                                class="sale-price"
                                *ngIf="
                                  item.unitPrice.sale !=
                                  item.extra.orginalPrice.price
                                "
                                >{{
                                  item.unitPrice.sale | currency: "GBP"
                                }}</span
                              >
                            </div>
                          </div>
                          <div class="gift-message-section">
                            <input
                              type="checkbox"
                              id="AddGiftMessage-{{ i }}"
                              class="form-check-input"
                              [checked]="getGeftCart(item) ?? false"
                              (click)="toggleTextarea(i, item, cart)"
                            />
                            <label
                              for="AddGiftMessage-{{ i }}"
                              class="form-check-label"
                            >
                              <span *ngIf="!activeTextareaIndex.includes(i)"
                                >Add gift message</span
                              >
                              <span *ngIf="activeTextareaIndex.includes(i)"
                                >Type gift message below</span
                              >
                            </label>
                            <textarea
                              *ngIf="activeTextareaIndex.includes(i)"
                              rows="4"
                              [value]="getGeftCart(item)"
                              (change)="UpdateItem($event, cart, item, '-1')"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-between mb-4">
                      <div class="col-4 mt-3">
                        <button
                          [disabled]="(isLoading$ | async)|| this.removedItem == item.lineItemId"
                          (click)="removeItem(cart.cartId, item.lineItemId)"
                          class="remove-btn"
                        >
                          <mat-icon>close</mat-icon> Remove
                        </button>
                      </div>
                      <div class="quantity-section col-4">
                        <label for="quantity-{{ i }}">Quantity</label>
                        <div>
                          <select
                            class="ea-input w-100 focus-ring"
                            [(ngModel)]="item.quantity"
                            (change)="UpdateItem($event, cart, item)"
                            id="quantity-{{ i }}"
                          >
                            <option
                              *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                              [value]="i"
                            >
                              {{ i }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        class="text-right font-bold font-title total-cost col-4"
                      >
            
                        <div>
                          {{
                            item.totalPrice.amount
                              | currency: item.totalPrice.currency
                          }}
                        </div>
                      </div>
                    </div>

                    <hr />
                  </div>

                  <div class="row desktop-view d-none d-md-block basket-item">
                    <div class="basket-item-details">
                      <div class="col-10">
                        <a
                          class="cart-item-name"
                          [routerLink]="[
                            '/product-details',
                            item.extra.parentName.description
                          ]"
                          [title]="item.title"
                        >
                          {{ item.extra.parentName.value }}
                        </a>
                      </div>
                      <div class="col-2">
                        <button
                          [disabled]="isLoading$ | async"
                          (click)="removeItem(cart.cartId, item.lineItemId)"
                          title="Remove item"
                        >
                          <mat-icon>close</mat-icon>
                          Remove
                        </button>
                      </div>
                    </div>

                    <!-- card image -->
                    <div class="row">
                      <div class="col-md-3 col-lg-3 cart-item-image mb-4">
                        <img
                          class="aspect-square object-cover"
                          alt="{{ item | attribute: 'image_alt_main' }}"
                          src="{{
                            item
                              | attribute: 'image_main_product'
                              | getImageFilter
                          }}"
                        />
                      </div>
                      <!-- end image -->
                      <!-- product-title -->
                      <div class="cart-item-detail col-md-9 col-lg-9 mb-4 px-4">
                        <div class="row justify-content-between mb-3">
                          <div class="col-7">
                            <br />
                            <span
                              *ngIf="item.title != item.extra.parentName.value"
                              class="size-color"
                              [innerHTML]="getItemTitle(item)"
                            ></span>
                            <!-- sold by -->
                            <!-- <div class="cart-item-vendor pb-2">
                          <span class="sold-by me-1">Sold by</span>
                          <span class="">{{
                              item | attribute: "vendor_name"
                            }}</span>
                        </div> -->
                          </div>
                          <!-- price amount -->
                          <div class="col-3 mt-3">
                            <ng-container
                              *ngIf="item.unitPrice.discount?.discountAmount"
                            >
                              <div
                                class="base-price line-through text-gray-500 font-title"
                              >
                                {{
                                  item.unitPrice.amount
                                    | currency: item.unitPrice.currency
                                }}
                              </div>
                            </ng-container>
                            <div
                              class="font-title text-right font-bold"
                              *ngIf="
                                item.unitPrice.sale ==
                                item.extra.orginalPrice.price
                              "
                            >
                              {{
                                item.unitPrice.sale
                                  | currency: item.unitPrice.currency
                              }}
                            </div>
                            <div
                              class="font-title text-right font-bold"
                              *ngIf="
                                item.unitPrice.sale !=
                                item.extra.orginalPrice.price
                              "
                            >
                              <span Class="old-price px-2">{{
                                item.extra.orginalPrice.price
                                  | currency: item.unitPrice.currency
                              }}</span>
                              <span
                                class="sale-price px-2"
                                *ngIf="
                                  item.unitPrice.sale !=
                                  item.extra.orginalPrice.price
                                "
                                >{{
                                  item.unitPrice.sale | currency: "GBP"
                                }}</span
                              >
                            </div>
                          </div>
                          <hr />
                          <div class="later-area">
                            <input
                              class="form-check-input focus-ring"
                              type="checkbox"
                              name="Addgiftmessage"
                              id="Addgiftmessage{{item.itemId}}"
                              alt="Add gift message"
                              [checked]="getGeftCart(item) ?? false"
                              (click)="toggleTextarea(i, item, cart)"
                            />
                            <label
                              for="Addgiftmessage{{item.itemId}}"
                              class="form-check-label"
                            >
                              <span *ngIf="!activeTextareaIndex.includes(i)">
                                Add gift message</span
                              >
                              <span *ngIf="activeTextareaIndex.includes(i)">
                                Type gift message below (maximum 128
                                characters)</span
                              >
                            </label>

                            <textarea
                              *ngIf="activeTextareaIndex.includes(i)"
                              rows="4"
                              [value]="getGeftCart(item)"
                              (change)="UpdateItem($event, cart, item, '-1')"
                            ></textarea>
                          </div>
                        </div>

                        <div class="row justify-content-between cart-item-font">
                          <div
                            class="col-xs-12 col-sm-6 flex flex-column justify-content-around"
                          >
                            <!-- Quantity -->
                            <div class="flex align-items-end mt-2">
                              <fieldset class="quantity-box mr-2">
                                <label
                                  for="quantity"
                                  class="font-title text-left cart-item-font w-100"
                                  >Quantity</label
                                >

                                <div class="quantity-input-wrapper">
                                  <button
                                    type="button"
                                    class="quantity-button"
                                    (click)="decreaseQuantity(item, cart)"
                                  >
                                    -
                                  </button>
                                  <input
                                    class="quantity-input text-center"
                                    type="text"
                                    id="quantity"
                                    [value]="item.quantity"
                                    min="1"
                                    [disabled]="(isLoading$ | async) === true"
                                    (change)="UpdateItem($event, cart, item)"
                                    alt="quantity input"
                                  />
                                  <button
                                    type="button"
                                    class="quantity-button"
                                    (click)="increaseQuantity(item, cart)"
                                  >
                                    +
                                  </button>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-5 quantity-col">
                            <!-- total price -->

                            <div
                              class="text-right font-bold font-title pt-2 total-cost"
                            >
                              <!-- <div
                                class="base-price line-through text-gray-500 font-title"
                                *ngIf="item.totalPrice.discount?.discountAmount"
                              >
                                {{
                                  item.totalPrice.amount
                                    | currency: item.unitPrice.currency
                                }}
                              </div> -->
                              <div>
                                {{
                                  item.totalPrice.amount
                                    | currency: item.unitPrice.currency
                                }}
                              </div>
                            </div>
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- end card item -->
        <div class="col-lg-4">
          <!--          <div class="mt-5"></div>-->
          <div *ngIf="(isLoading$ | async) === true">
            <div class="row">
              <div class="col-lg-12">
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{
                    'height.px': 150,
                    'background-color': '#e4e4e4'
                  }"
                  [animation]="false"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="subtotal-content d-none d-md-block col-lg-12 mt-4">
            <ng-container *ngIf="cart$ | async as cart">
              <ng-container *ngIf="cart.totalItems > 0">
                <div
                  class="total-card"
                >
                  <div class="row ps-2 pe-2 cart-totals">
                    <ea-cart-totals></ea-cart-totals>
                    
                  </div>
                  <div class="row ps-3 pe-3">
                    <button
                      (click)="gotoCheckout()"
                      class="ea-button ea-button-primary  w-100 p-1 font-title font-bold"
                      [disabled]="isButtonsDisabled$ | async"
                    >
                      Confirm delivery options
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>


          <!-- <div class="toast-div d-none d-md-block col-lg-12 mt-4 desktop-view"   style="min-width: 300px !important;">
            <ea-toast 
            style="min-width: 300px !important;"
            *ngIf=" this.cartService.toast"
            [toast]=" this.cartService.toast"
            (dispose) ="closeToast()"
            ></ea-toast> -->
          <!-- </div> -->
     
          <ea-subtotal-mobile></ea-subtotal-mobile>

<!--          <div class="subtotal-content-mobile col-lg-12">-->
<!--            <ng-container *ngIf="cart$ | async as cart">-->
<!--              <ng-container *ngIf="cart.totalItems > 0">-->
<!--                <div>-->
<!--                  <div class="justify-content-between m-2 row">-->
<!--                    <div class="col">-->
<!--                      <span>Bag( {{ cart.totalItems }} items)</span>-->
<!--                    </div>-->
<!--                    <div class="col text-right total-price">-->
<!--                      <span>{{-->
<!--                        total$ | async | currency: cart.currency-->
<!--                      }}</span>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="row ps-3 pe-3 delevery-button">-->
<!--                    <button-->
<!--                      (click)="gotoCheckout()"-->
<!--                      class="ea-button ea-button-primary text-uppercase w-100 p-1 font-title font-bold"-->
<!--                      [disabled]="isButtonsDisabled$ | async"-->
<!--                    >-->
<!--                      Confirm delivery-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </ng-container>-->
<!--            </ng-container>-->
<!--          </div>-->
        </div>
      </ng-container>
    </div>

    <ng-template #empty>
      <p class="text-theme-on-background">You have no items in your basket</p>
    </ng-template>
  </div>
</section>
