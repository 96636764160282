<section>
  <div class="container" style="width: 100%">
    <div style="padding: 0px; margin-bottom: 0px" *ngIf="loader; else content">
      <div class="row">
        <div class="col-sm-12 col-md-9 pt-5">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 200,
              'background-color': '#e4e4e4'
            }"
            [animation]="false"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <ng-template #content>
      <div class="d-flex justify-content-between disctop">
        <h1 style="font-size: 30px">Preferred charity</h1>
        <div class="toast-div">
          <ea-toast
            *ngIf="toast"
            [toast]="toast"
            (dispose)="closeToast()"
          ></ea-toast>
        </div>
      </div>
      <div class="cards-list row mx-auto rounded w-100 mt-2">
        <ea-charity-choose
          [charities]="charities"
          [disability]="disability"
          [customeId]="customeId"
          [selectedDedisability]="selectedDedisability"
          [selected]="selected"
          [isCharityPage]="true"
          (changeDisabilityEvent)="changeDisability($event)"
          (onOptionsChangedEvent)="onOptionsChangedEvent($event)"
        ></ea-charity-choose>
      </div>
    </ng-template>
  </div>
</section>
