<div class="ea-card-grid col-12 rounded" [ngStyle]="styles">
  <div class="card-body d-flex align-items-center justify-between">
    <div class="d-flex flex-row align-items-center">
      <div class="cards-img">
        <img class="card-img" [src]="cardImage" alt="{{ cardTitle }}" />
      </div>
      <div class="card-title font-bold">
        {{ cardTitle | titlecase }} <br>
        <span class="mobile">{{ last4 }}</span>
      </div>
    </div>

    <div class="d-flex align-items-center justify-between">
      <div class="font-bold disctop" style="margin-left: -100px;">
        {{ last4 }}
      </div>
      <div class="ml-2 disctop">
        <button (click)="toggleDateForm()">
          <u><mat-icon>arrow_drop_down</mat-icon><span>Update expiry date</span> <span class="mobile">Edit</span>  </u>
        </button>
      </div>
      <div>
        <div class="card-icon" (click)="cardClosed.emit()">
          <u><span><mat-icon>close</mat-icon> Delete</span></u>
          
        </div> 
        <div class="ml-2 mobile">
          <button (click)="toggleDateForm()">
            <u><span ><mat-icon>arrow_drop_down</mat-icon> Edit</span>  </u>
          </button>
        </div>
      </div>
   
    </div>
  </div>

  @if (showDateForm) {
  <hr class="mt-3">
  <div class="w-100 align-items-center d-flex">
    <div class="col-10" style="width: 90%;">
      <ea-form-field
        class="form-field col-sm-12 col-md-3 d-flex justify-content-between align-items-between flex-column row w-100"
        label="Expiry date MM/YY"
        name="expiryMonth"
        alt="Expiry Month input"
        [form]="form"
        [expiredDate]="true"
      ></ea-form-field>
    </div>
    <div class="col-2 mt-4">
      <img
        src="assets/images/cardsIcons/cardExpiryDate.svg"
        style="width: 60px; margin-top: 30px"
        alt="Card number"
      />
    </div>
  </div>
  <div class="row w-100">
    <button
      type="submit"
      (click)="saveCard()"
      class="ea-button ea-button-primary col-lg-12 col-xl-12 focus-ring w-100 mt-3"
      [disabled]="form.invalid "
    >
      <span class="material-icons">lock</span>
      Save payment card
    </button>
  </div>
  }
</div>
