import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

declare const SR: any;
declare var freshpaint: any;

@Component({
  selector: 'ea-footer',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  newsLetterForm!: FormGroup;
  isComplete$ = new BehaviorSubject<boolean>(false);
  isSubmit: boolean = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private builder: FormBuilder) {
    this.newsLetterForm = this.builder.group({
      // firstname: ['', Validators.required],
      // lastname: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      newsletterAgreement: ['enabled'],
    });
  }

  submit(event: Event) {
    this.isSubmit = true;
    event.preventDefault();

    const value = this.newsLetterForm.value;

    SR.event.sendFormData('newsletter', value);
    freshpaint.track("sign_up", {
      method :"synerise"
        });
    this.isComplete$.next(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
