<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="container">
          <div *ngIf="user$ | async as user">
            <ng-container *ngIf="cart$ | async as cart">
              <ea-payment-form [user]="user" [bullingAddress]="bullingAddress" [newBullingAddress]="newBullingAddress"></ea-payment-form>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

