import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { BaseComponent } from '@ea/components';
import { IResponseAddress } from '@ea/models';
import { AddressService } from '@ea/services';
declare var freshpaint: any;
@Component({
  selector: 'ea-address',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterLink, MatIcon],
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent extends BaseComponent {
  @Input() address?: any;
  @Output() deletesAddress = new EventEmitter();
  @Output() editsAddress = new EventEmitter();
  @Input() userCustomId: any;
  @Output() addressUpdated = new EventEmitter();
  @Output() laoding =  new EventEmitter();
  constructor(private addressService: AddressService) {
    super();
  }
  transform(value: string): string {
    if (!value) {
      return value;
    }
    // Convert the entire string to lowercase
    value = value.toLowerCase();
    // Split the string into words
    const words = value.split(' ');
    // Capitalize the first character of each word
    for (let i = 0; i < words.length; i++) {
      if (words[i]) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
    }
    // Join the words back together with spaces
    return words.join(' ');
  }
  deleteAddress(addressId: string) {
    freshpaint.track("click",{
      Page_type :"Address Book",
      Page_name :"Address Book",
      Click_name: "Button",
      Click_value :"Remove"
    });
    this.deletesAddress.emit(addressId);
  }

  editsAddressEvent() {
    freshpaint.track("click",{
      Page_type :"Address Book",
      Page_name :"Address Book",
      Click_name: "Button",
      Click_value :"Edit"
    });
    this.editsAddress.emit(this.address.addressId);
  }
  toggleDefault(address: IResponseAddress, isDefault: boolean) {
    this.laoding.emit();
    this.toggleDisable(true);
    if (isDefault) {
      address.additionalAttributes.isBillingAddress =
        !address.additionalAttributes.isBillingAddress;
    } else {
      address.additionalAttributes.isShippingAddress =
        !address.additionalAttributes.isShippingAddress;
    }
    this.subscriptions.push(
      this.addressService
        .updateAddress({
          addressId: address.addressId,
          isDefaultAddress: isDefault,
          customId: this.userCustomId,
          isShippingAddress: address.additionalAttributes.isShippingAddress,
          isBillingAddress: address.additionalAttributes.isBillingAddress,
          type: address.type,
          addressLineOne: address.addressLine1,
          addressLineTwo: address.addressLine2,
          addressLineThree: address.addressLine3,
          addressCity: address.city,
          addressCounty: address.county,
          addressPostcode: address.postalCode,
          addressCountry: address.country,
          phone: address.phone.number,
          first: address.additionalAttributes.first,
          last: address.additionalAttributes.last,
          NickName : address.additionalAttributes.nickName
        })
        .subscribe({
          next: (result: any) => {


            this.addressUpdated.emit(
              true
            )

          },
          error: () => {

            this.addressUpdated.emit(
              false
            )
          },
        }),
    );
  }
}
