import { Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Observable, tap } from 'rxjs';
import { CartService } from '../../services';
import { FormsModule } from '@angular/forms';
import { VendorService } from '@ea/services';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ea-delivery-options',
  standalone: true,
  imports: [CommonModule, FormsModule,MatIcon],
  providers: [DatePipe],
  templateUrl: './delivery-option.component.html',
  styleUrls: ['./delivery-option.component.scss'],
})
export class DeliveryOptionsComponent implements OnInit {
  @Input() vendorId?: string;
  @Input() shipmentMethodId?: string;
  @Output() optionChanged = new EventEmitter<any>();
  @Input() vendor: any;
  options$?: Observable<any>;
  selected: any;
  ShowCardList = false;
  maxCost : number = 0;
  ngOnInit(): void {
    if (this.vendorId) {
      this.options$ = this.vendorService.getShippingMethods(this.vendorId).pipe(
        tap((x) => {
          const saved = x.shipMethods.find(
            (x: any) => x.shippingMethodId === this.shipmentMethodId,
          );
          this.selected = saved ?? this.selected; // Preserve default value if no 'saved' 

          this.vendor.items.forEach(( x :any) =>{
            this.maxCost+=x.totalPrice.amount
          })
          x.shipMethods = x.shipMethods
          .filter((x: any) => x.cartValue.min < this.maxCost)
          .sort((a: any, b: any) => a.cost - b.cost);
          if(!this.selected){
          this.onOptionsChanged(x.shipMethods[0])
          }
          x.shipMethods.map((item: any) => {
            item.description = item.description.split('|')[0];
          });
        }),
      );
    }
  }
  transform(value: number, digits: number = 2): string {
    return value.toFixed(digits);
  }
  onOptionsChanged(option: any) {
    this.optionChanged.emit(option);
  }

  showList() {
    this.ShowCardList = !this.ShowCardList;
  }

  getFormattedCutOffTime(shipMethod : any): string {
    const date = new Date();
    date.setHours(shipMethod, 0, 0, 0); // Set hours and minutes
    return this.datePipe.transform(date, 'h:mm a')!;
  }
  constructor(
    private service: CartService,
    private vendorService: VendorService,
    public datePipe: DatePipe
  ) {}
}
