import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardsGridComponent } from './components';
import { MatIconModule } from '@angular/material/icon';
import { FormFieldComponent } from 'src/app/components/form-field/form-field.component';

const exported = [CardsGridComponent];
@NgModule({
  declarations: exported,
  imports: [CommonModule, MatIconModule,FormFieldComponent],
  exports: exported,
  providers: [],
})
export class SharedModule {}
