import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';

import { BaseComponent } from '@ea/components';
import { AddressEditorComponent } from 'src/app/modules/address-editor/components';
import environment from 'src/environments/environment';

@Component({
  selector: 'ea-selected-address',
  standalone: true,
  imports: [CommonModule,AddressEditorComponent,FormsModule,MatIcon,RouterLink],
  templateUrl: './selected-address.component.html',
  styleUrl: './selected-address.component.scss'
})
export class SelectedAddressComponent   extends BaseComponent implements OnInit{
  @Input() address?: any;
  @Input() addresses: any[] = [];
  @Output() onAddressSelected = new EventEmitter(true);
  isDeliveryCompante: boolean = true;
  @Input() isBulling : boolean =false;
  @Output() goToDelevery = new EventEmitter(true);
  @Input() bullingAddress? : any;
  @Output() useThisAddressAsBilling = new EventEmitter(true);
  currentCompnant : number =0;
  useingDeliveryAsBilling : boolean = true;
  
  public get availableAddresses() {
    return this.addresses;
  }

  goToDeliveryOption(){
    this.isDeliveryCompante = false;
    this.goToDelevery.emit();
  }
  transform(value: string): string {
    if (!value) {
      return value;
    }
    // Convert the entire string to lowercase
    value = value.toLowerCase();
    // Split the string into words
    const words = value.split(' ');
    // Capitalize the first character of each word
    for (let i = 0; i < words.length; i++) {
      if (words[i]) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
    }
    // Join the words back together with spaces
    return words.join(' ');
  }
  chnageComponant(index : number){
    this.currentCompnant = index;
  }

  updateAddress(event: any){
    this.chnageComponant(0);
    
    if(event){
    this.address = event;
    this.address.phone.number = event.additionalAttributes.phone;
    this.address.first = event.additionalAttributes.first;
    this.address.last = event.additionalAttributes.last;
    }
  }

  
  checkCountryAvailable(address: any) {
    if (environment.base === '') {
      return false; //enable all addresses for dev and local envs
    }

    const lowerCaseCountry = address.country.toLowerCase();
    switch (lowerCaseCountry) {
      case 'united kingdom':
      case 'gb':
        return environment.base !== 'uk'; //to disable not uk address for /uk envs
        case 'gbp':
          return environment.base !== 'uk';
      case 'gbr':
        return environment.base !== 'uk';
      case 'usa':
        return environment.base !== 'us'; //to disable not usa address for /us envs

      default:
        return true;
    }
  }
  onAddressSelecteds(address: any | null) {

    if(address){
    this.onAddressSelected.emit(address);
  }
    this.chnageComponant(0);
  }

  
  useAsBillingAddress(){
    this.useingDeliveryAsBilling =!this.useingDeliveryAsBilling;
    this.useThisAddressAsBilling.emit();
  }
  constructor(private router : Router){
    super();

  }
  ngOnInit(): void {
   if(this.isBulling && this.bullingAddress){
    this.currentCompnant = 4;
   }
  }
}
