
<div
tabindex="0"
  itemprop="offers"
  itemscope
  itemtype="http://schema.org/Offer"
  class="product-price row"
  *ngIf="price?.sale"
>
<div class="col-10">
  <h2 itemprop="price" class="text-theme-on-surface" *ngIf="price" >
    <span [ngClass]="{ 'old-price': price.sale }" itemprop ="RegularPrice">
      {{ price!.baseValue | currency : price!.currency }}
    </span>
    <span class="sale-price" *ngIf="price.sale ?? false" itemprop="SalePrice">
      {{ price.sale | currency : price.currency }}
    </span>
  </h2>
</div>
<div class="col-2 mobile">
  <div class="mb-0 mr-2 p-0" (click)="triggerLike()">
    <span
    style="align-items: end; text-align: end;"
      class="m-2 like "
      *ngIf="isLike"
    >
      <mat-icon >favorite</mat-icon>
    </span>
    <span
      class="m-2 unlike "
      *ngIf="!isLike"
    >
      <mat-icon class="unlike" >favorite_border</mat-icon>
    </span>
  </div>
</div>


</div>
<div
tabindex="0"
  itemprop="offers"
  itemscope
  itemtype="http://schema.org/Offer"
  class="product-price row"
  *ngIf="
 !price?.sale && priceRange && (
    priceRange.min == 0 ||
    priceRange.max == 0 ||
    (priceRange.min == priceRange.max )|| !priceRange
  )
  "
>
<div class="col-10">
  <h2 class="text-theme-on-surface" *ngIf="price ?? true" >
    <span itemprop ="RegularPrice">{{ priceRange?.max ?? 0 | currency : currency }}</span> 

  </h2>
</div>
  <div class="col-2 mobile">
    <div class="mb-0 mr-2 p-0" (click)="triggerLike()">
      <span
      style="align-items: end; text-align: end;"
        class="m-2 like "
        *ngIf="isLike"
      >
        <mat-icon >favorite</mat-icon>
      </span>
      <span
        class="m-2 unlike "
        *ngIf="!isLike"
      >
        <mat-icon class="unlike" >favorite_border</mat-icon>
      </span>
    </div>
  </div>
</div>
<div class="product-price row" tabindex="0"  *ngIf="
priceRange.min != 0 &&
priceRange.max != 0 &&
priceRange.min != priceRange.max &&
!price!.sale
">
  <div class="col-10">
  <h2
    class="text-theme-on-surface"

  
  >
  <span     itemprop="PriceRange">    {{ priceRange.min | currency : currency }}
    -
    {{ priceRange.max | currency : currency }}</span>

  </h2>
</div>
  <div class="col-2 mobile">
    <div class="mb-0 mr-2 p-0" (click)="triggerLike()">
      <span
      style="align-items: end; text-align: end;"
        class="m-2 like "
        *ngIf="isLike"
      >
        <mat-icon >favorite</mat-icon>
      </span>
      <span
        class="m-2 unlike "
        *ngIf="!isLike"
      >
        <mat-icon class="unlike" >favorite_border</mat-icon>
      </span>
    </div>
  </div>

</div>
@if(!isChanged)
{
<div class="payment-installment button mt-4"> <br> <br></div>
}
@if(isChanged)
{

    <button class="payment-installment button mt-4 desktop"(click)="showClearPay()" tabindex="0">
      @if(price!.sale){
      <span class="installment-text  mr-4" style="margin-right: 20px;">
      Make {{ 4 }} interest-free <br>
      <span >  &nbsp;  &nbsp;   &nbsp; payments of £{{ price!.sale / 4 | number:'1.2-2' }} with </span>

      </span>}
      @else{
        <span class="installment-text  mr-4" style="margin-right: 20px;">
          Make  {{ 4 }} interest-free  <br>
          <span> &nbsp;  &nbsp;   &nbsp;  &nbsp; &nbsp; payments of £{{ priceRange.max / 4  | number:'1.2-2'}} with </span>

      </span>}
        <div class="clearpay-logo-div  mr-4" style="margin-right: 20px;">
          <img alt="clearpay-logo" class="clearpay-logo" src="assets/images/clearpay-logo-blackmint.png" />
      </div>
    
      <span class="info-icon" (mouseenter)="showInfo = true" (mouseleave)="showInfo = false" >
        <u>Learn more</u>
      </span>
    </button>



    
    <button class="payment-installment button mt-4 mobile"(click)="showClearPay()" tabindex="0">
      @if(price!.sale){
      <span class="installment-text  ml-2" style="font-size: 18px;">
      Make {{ 4 }} interest-free  payments of £{{ price!.sale / 4 | number:'1.2-2' }} with 

      </span>}
      @else{
        <span class="installment-text  ml-2" style="font-size: 18px;">
          Make  {{ 4 }} interest-free    payments of £{{ priceRange.max / 4  | number:'1.2-2'}} with 

      </span>}
        <div class=" mr-2 d-flex justify-content-center mt-2">
          <img alt="clearpay-logo" class="clearpay-logo  mr-4" src="assets/images/clearpay-logo-blackmint.png" />
          <u >Learn more</u>
      </div>
    
      <span class="info-icon" (mouseenter)="showInfo = true" (mouseleave)="showInfo = false" >
   
      </span>
    </button>
  @if(showClearPayment){
    @if(price!.sale && price!.sale <= 1400 ){
      <app-bnpl-info *ngIf="price!.sale" [purchasePrice]="price!.sale/4" [installmentPrice]="price!.sale" class="m-1" tabindex="0">
  
      </app-bnpl-info>
    }
    @if(priceRange.max && priceRange.max <= 1400 && !price!.sale ){
      <app-bnpl-info [purchasePrice]="priceRange!.max/4" [installmentPrice]="priceRange!.max" class="m-1" tabindex="0">
  
      </app-bnpl-info>
    }

  } 
}