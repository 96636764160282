<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="container">
          <ea-order-steps [step]="'Auth'" class="mb-4"></ea-order-steps>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="container">
          <div class="mt-4">
            <div class="content">
              <div class="header-container pb-0">
                @if(openLogin && !openSignUp){
                <p>Log in to your account</p>
                } @else if(!openSignUp){
                <p>Do you have an account?</p>
                }

                <div class="text-right" *ngIf="openSignUp">
                  <button (click)="cancel()" class="remove-btn">
                    <mat-icon>close</mat-icon> Cancel
                  </button>
                </div>

                <div
                  class="d-flex justify-between flex-wrap mt-3"
                  *ngIf="!openSignUp"
                >
                  <button
                    [ngClass]="
                      openLogin && !openSignUp
                        ? 'ea-button ea-button-stroked'
                        : 'ea-button ea-button-primary'
                    "
                    (click)="goToLogin()"
                    class="my-2"
                  >
                    Log in to my account
                    <mat-icon>arrow_drop_down</mat-icon>
                  </button>
                  <button
                    class="ea-button ea-button-stroked my-2"
                    (click)="goToSignUp()"
                  >
                    Create an account
                  </button>
                  
                </div>
         
              </div>
              <div class="header-container col-12 col-sm-9" *ngIf="openLogin && !openSignUp">
                <ea-login-form [isCartAuth]="true"></ea-login-form>
              </div>
              <div class="header-container col-12" *ngIf="!openLogin && openSignUp">
                <ea-cart-create-account></ea-cart-create-account>
              </div>
              @if(!openSignUp){
                <div  class="header-container pb-0">
                <p class="mt-4">Why Create an Account?</p>
                <ul class="m-4" style="font-size: 16px;">
                  <li class="mt-1"><span class="font-bold"> • Unlock Savings: </span>  Create an account to access promotion codes and discounts.</li>
                  <li class="mt-1"><span class="font-bold"> • Fast Checkout: </span>  Save your details securely to speed through future purchases.</li>
                  <li class="mt-1"><span class="font-bold"> • Order Tracking: </span>  Easily monitor your orders every step of the way.</li>
                  <li class="mt-1"><span class="font-bold"> • Seamless Returns: </span>  Manage returns quickly and easily through your account.</li>

                </ul>
              </div>
                }
              <!--          <ng-container *ngIf="openLogin">-->
              <!--            <ea-login></ea-login>-->
              <!--          </ng-container>-->

              <!--          <ng-container *ngIf="openSignUp">-->
              <!--            <p>Create account</p>-->
              <!--          </ng-container>-->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 subtotal-content ">
        <div class="container">
          <div class="d-none d-md-block col-lg-12 mt-4" style="margin-top: 50%">
            <ng-container *ngIf="cart$ | async as cart">
              <ng-container *ngIf="cart.totalItems > 0">
                <div
                  class="card bg-theme-surface focus-outline text-theme-on-surface"
                >
                  <div class="row ps-2 pe-2 cart-totals">
                    <ea-cart-totals></ea-cart-totals>
                  </div>
                  <div class="row ps-3 pe-3">
                    <button
                      class="ea-button ea-button-stroked w-100 p-1 font-title font-bold"
                      [disabled]="true"
                    >
                      Confirm delivery options
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <ea-info-card [displayEALLDev]="true"></ea-info-card>
        </div>
      </div>
      <ea-subtotal-mobile [isAuthPage]="true"></ea-subtotal-mobile>
    </div>
  </div>
</section>
