<div class="card-form disctop">
  <div class="w-100 align-items-center d-flex">
    <div class="col-10"  style="width: 89%;">
      <ea-form-field
        label="Card Number"
        name="cardNumber"
        alt="Card Number input"
        [form]="form"
      ></ea-form-field>
    </div>
    <div class="col-2 mt-5">
      <img
        src="assets/images/cardsIcons/cardNumberIcon.svg"
        style="width: 60px; margin-left: 10px;"
        alt="Card number"
      />
    </div>
  </div>
  <div class="w-100 align-items-center d-flex">
    <div class="col-10" style="width: 89%;">
      <ea-form-field
        label="Expiry date MM/YY"
        name="expiryDate"
        alt="Expiry Date input"
        [form]="form"
        [expiredDate]="true"
      ></ea-form-field>
    </div>
    <div class="col-2 mt-5">
      <img
        src="assets/images/cardsIcons/cardExpiryDate.svg"
        style="width: 60px; margin-left: 10px;"
       alt="Card number"
      />
    </div>
  </div>
  <div class="w-100 align-items-center d-flex">
    <div class="col-10" style="width: 89%;">
      <ea-form-field
        label="CVC"
        name="cvc"
        alt="cvc input"
        [form]="form"
      ></ea-form-field>
    </div>
    <div class=" col-2 mt-5 text-right float-right">
      <img
        src="assets/images/cardsIcons/CVC.svg"
        style="width: 60px; margin-left: 10px;"
        alt="Card number"
      />
    </div>
  </div>

  <div class="row w-100">
    <div class="col-12">
      <ea-form-field
        label="Name on card"
        name="fullName"
        alt="Name on card"
        [form]="form"
      ></ea-form-field>
    </div>
  </div>
  <div class="row w-100">
    <button
      type="submit"
      (click)="saveCard()"
      class="ea-button ea-button-primary col-lg-12 col-xl-12 focus-ring w-100 mt-3"
      [disabled]="form.invalid || (isLoading$ | async)"
    >
      <span class="material-icons">lock</span>
      Save payment card
    </button>
  </div>  
</div>


<div class="card-form mobile">
  <div class="row w-100">
    <div class="col-9">
      <ea-form-field
        label="Card Number"
        name="cardNumber"
        alt="Card Number input"
        [form]="form"
      ></ea-form-field>
    </div>
    <div class="col-3 mt-5">
      <img
        src="assets/images/cardsIcons/cardNumberIcon.svg"
        class="card-icon"
        alt="Card number"
      />
    </div>
  </div>
  <div class="row w-100">
    <div class="col-9">
      <ea-form-field
        label="Expiry date MM/YY"
        name="expiryDate"
        alt="Expiry Date input"
        [form]="form"
        [expiredDate]="true"
      ></ea-form-field>
    </div>
    <div class="col-3 mt-5">
      <img
        src="assets/images/cardsIcons/cardExpiryDate.svg"
        style="width: 60px; margin-top: 30px"
       alt="Card number"
      />
    </div>
  </div>
  <div class="row w-100 ">
    <div class="col-9">
      <ea-form-field
        label="CVC"
        name="cvc"
        alt="cvc input"
        [form]="form"
      ></ea-form-field>
    </div>
    <div class=" col-3 mt-5 text-right float-right">
      <img
        src="assets/images/cardsIcons/CVC.svg"
        class="card-icon"
        alt="Card number"
      />
    </div>
  </div>

  <div class="row w-100">
    <div class="col-12">
      <ea-form-field
        label="Name on card"
        name="fullName"
        alt="Name on card"
        [form]="form"
      ></ea-form-field>
    </div>
  </div>
  <div class="row">
    <button
      type="submit"
      (click)="saveCard()"
      class="ea-button ea-button-primary col-lg-12 col-xl-12 focus-ring w-100 mt-3"
      [disabled]="form.invalid || (isLoading$ | async)"
    >
      <span class="material-icons">lock</span>
      Save payment card
    </button>
  </div>
</div>
<!--<div class="mt-3 text-right">-->
<!--  <input-->
<!--    type="checkbox"-->
<!--    class="form-check-input focus-ring"-->
<!--    [checked]="saveCart"-->
<!--    style="width: 20px; height: 20px"-->
<!--    (change)="saveCart = !saveCart"-->
<!--    aria-label="Remember me"-->
<!--    alt="save card checkbox"-->
<!--  />-->
<!--  <label class="m-1"> Save card</label>-->
<!--</div>-->
