import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
  ) {}

  canActivate(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      let user = localStorage.getItem('user');
      if (user) return true;
      else {
        this.router.navigate(['/login']);
        return false;
      }
    }
    // Default to preventing access if platform is unknown
    return false;
  }
}
