import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  SyneriseListFilter,
  SyneriseQueryMainFilter,
  SyneriseResponse,
} from '../models/synerise.model';
import environment from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SyneriseService {
  constructor(private api: HttpClient) {}

  list(filter: SyneriseListFilter): Observable<SyneriseResponse> {
    const url = `https://api.synerise.com/search/v2/indices/${environment.synerise.indexId}/list?token=${environment.synerise.token}`;
    if(environment.base == "uk"){
      if(  !filter.filters.includes("AND Active==\"true\"")){
      filter.filters += " AND Active==\"true\"";
      }
    }
    else{
      if(  !filter.filters.includes("AND Active==\"true\"")){
    filter.filters += " AND Active==\"true\" AND en_us==\"true\"";
      }
    }
    return this.api.post<SyneriseResponse>(url, filter);
  }

  query(filter: SyneriseQueryMainFilter): Observable<SyneriseResponse> {
    const url = `https://api.synerise.com/search/v2/indices/${environment.synerise.indexId}/query?token=${environment.synerise.token}`;
      if(environment.base == "uk"){
        if(  !filter.filters.includes("Active==\"true\"")){
          if(  filter.filters){
        filter.filters += "AND Active==\"true\"";
          }
          else{
            filter.filters = "Active==\"true\"";
          }
        }
      }
      else{
        if(  !filter.filters.includes("Active==\"true\"")){

       if(  filter.filters){
        filter.filters += "AND Active==\"true\" AND en_us==\"true\"";
          }
          else{
            filter.filters = "Active==\"true\" AND en_us==\"true\""; 
          }
        }
      }
    return this.api.post<SyneriseResponse>(url, filter);
  }

  itemClickedInSearch(event : any){
    const url = `https://api.synerise.com/v4/events/ai-compat/item.search.click?token=${environment.synerise.token}`;
    return this.api.post(url,event);    
  }
  sameProducts(sku : string): Observable<SyneriseResponse> {
    const url = `https://api.synerise.com/recommendations/v2/recommend/campaigns/${environment.synerise.recommendation}?displayAttribute=image_alt_main&displayAttribute=sale&includeContextItems=true&token=${environment.synerise.token}&itemId=${sku}&displayAttribute=display_name`;
    return this.api.get<SyneriseResponse>(url);    
  } 
}
