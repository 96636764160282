<form
  [formGroup]="loginForm"
  class="rounded ea-form bg-theme-surface"
  (ngSubmit)="login()"
>
  <p class="text-theme-on-surface" *ngIf="!isCartAuth">
    If you already have an account with EnableAll, please login using
    the form below.
  </p>
  <div class="form-group mt-4">
    <label class="form-label ea-label required" for="emailAddress"
    >Email</label
    >
    <input
      type="email"
      class="ea-input w-100 focus-ring"
      id="emailAddress"
      placeholder="Enter email"
      formControlName="email"
      alt="email input"
    />
  </div>

  <div class="form-group mt-4">
    <label class="form-label ea-label required" for="password"
    >Password</label
    >

    <div class="input-group">
      <input
        [type]="passwordVisible ? 'text' : 'password'"
        placeholder="Password"
        class="ea-input form-control-flex focus-ring"
        id="password"
        formControlName="password"
        alt="password input"
      />
      <div class="input-group-text py-0">
        <button
          type="button"
          class="btn btn-dark m-0 p-0 focus-ring"
          mat-icon-button
          matSuffix
          (click)="togglePasswordVisibility()"
        >
                    <span class="material-icons">{{
                        passwordVisible ? "visibility" : "visibility_off"
                      }}</span>
        </button>
      </div>
    </div>
    <div class="forgot-password text-right">
      <a routerLink="/recover">Forgot your password</a>
    </div>
    <button
      type="submit"
      class="ea-button ea-button-primary col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2 focus-ring"
      [disabled]="loginForm.invalid || (isLoading$ | async)"
    >
      <span class="material-icons">person</span>
      <strong>Log in</strong>
    </button>
  </div>

  <div class="login-list">
    <h2 class="text-theme-on-surface my-3 text-center">
      Or you can try one of following options:
    </h2>
    <a
      [routerLink]="'/generate-link'"
      [ngClass]="{ disabled: (isLoading$ | async) }"
      class="ea-button ea-button-stroked col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2 focus-ring"
    >
      Send a link in an email
    </a>
    <div class="disctop">
    <a
      [routerLink]="'/generate-qr-code'"
      [ngClass]="{ disabled: (isLoading$ | async) }"
      class="ea-button ea-button-stroked col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2 focus-ring mt-4 "
    >
      Use the EnableAll app and QR code
    </a>
  </div>
    <a
      [routerLink]="'/login-by-code'"
      [ngClass]="{ disabled: (isLoading$ | async) }"
      class="ea-button ea-button-stroked col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2 focus-ring mt-4"
    >
      Send me a log in code
    </a>
  </div>
</form>
