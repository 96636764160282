import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bnpl-info',
  templateUrl: './bnpl-info.component.html',
  styleUrl: './bnpl-info.component.scss',
  standalone: true,
  imports: [CommonModule]
})
export class BnplInfoComponent {
  @Input() currencySymbol: string = '£';
  @Input() purchasePrice: any ;
  @Input() installmentPrice: any ;
}
