<!--MOBILE MENU-->

<div class="auth-links-mobile mobile">
  <div class="d-flex flex-row">
    <ng-container *ngIf="isAuthenticated$ | async; else noAuthMobile">
      <!--    <li class="nav-item bg-light-gray ps-2">-->
      <button
        class="nav-link font-title font-bold justify-content-center search-btn"
        aria-current="page"
        data-bs-toggle="collapse"
        (click)="scrollToSearch()"
      >
        <span class="material-icons">search</span>
<!--        <span>Search</span>-->
      </button>
      <a
        [routerLink]="'account'"
        class="nav-link font-title font-bold"
        data-bs-toggle="collapse"
      >
        <span class="material-icons">person</span>
        <span>Account</span>
      </a>
      <!--    </li>-->

      <!--    <li class="nav-item bg-light-gray border-top-gray-1px ps-2">-->
      <a
        [routerLink]="'cart'"
        class="nav-link font-title font-bold active"
        aria-current="page"
        data-bs-toggle="collapse"
      >
        <span class="material-icons bag-icon mx-2">
          <img class="bag-icon" alt="bag icon" src="assets/images/icons/bag.svg" />
          <span class="counter" *ngIf="this.showCount">
            {{ cartProductsCount$ | async }}
            <span class="visually-hidden"> items </span>
          </span>
        </span>
        <span>Bag</span>

        <!--        Bag-->

        <!--        <span class="mobile-counter float-right" *ngIf="this.showCount">-->
        <!--          {{ cartProductsCount$ | async }}-->
        <!--        </span>-->
      </a>
      <!--    </li>-->
    </ng-container>

    <ng-template #noAuthMobile>
      <li class="nav-item ps-md-2 search-btn">
        <button
          class="nav-link font-title font-bold active justify-content-center"
          aria-current="page"
          data-bs-toggle="collapse"
          (click)="scrollToSearch()"

        >
          <span class="material-icons">search</span>
          <span>Search</span>
        </button>
      </li>
      <li class="nav-item ps-md-2">
        <a
          routerLink="/login"
          class="nav-link font-title font-bold active"
          aria-current="page"
          data-bs-toggle="collapse"
        >
          <span class="material-icons">person_outline</span>
          <span>Log in</span>
        </a>
      </li>

      <li class="nav-item ps-md-2">
        <a
          [routerLink]="'cart'"
          class="nav-link font-title font-bold active"
          aria-current="page"
          data-bs-toggle="collapse"
        >
          <span class="material-icons bag-icon mx-2">
            <img class="bag-icon" src="assets/images/icons/bag.svg" alt="bag icon" />  
            <span class="counter" *ngIf="this.showCount">
              {{ cartProductsCount$ | async }}
              <span class="visually-hidden"> items </span>
            </span>
          </span>
          <span>Bag</span>
        </a>
      </li>
    </ng-template>
  </div>
</div>

<!--END MOBILE MENU-->

<div class="desktop">
  <div class="row">
    <div class="col-md-6 col-lg-6 p-0">
      <ng-container *ngIf="isAuthenticated$ | async; else noAuth">
        <div class="account w-100 d-flex justify-content-end">
          <a
            [routerLink]="'/account'"
            class="ea-button ea-button-secondary w-100 focus-ring justify-content-around"
          >
            <span class="material-icons">
              <mat-icon>account_circle</mat-icon>
            </span>

            My account
          </a>
        </div>
      </ng-container>

      <ng-template #noAuth>
        <div class="login w-100 d-flex justify-content-end">
          <a
            routerLink="/login"
            class="ea-button ea-button-stroked w-100 focus-ring justify-content-around"
          >
            <span class="material-icons"><mat-icon>person</mat-icon></span>

            Log in
          </a>
        </div>
      </ng-template>
    </div>

    <div class="col-md-6 col-lg-6 pe-0">
      <div class="basket w-100 float-left">
        <a
          routerLink="/cart"
          class="ea-button w-100 focus-ring d-flex align-items-center"
          [ngClass]="showCount ? 'ea-button-secondary' : 'ea-button-stroked '"
        >
          <span class="material-icons bag-icon mx-2">
            <!--            <mat-icon>shopping_bag</mat-icon>-->
            <div *ngIf="showCount; then thenBlock; else elseBlock"></div>
            <ng-template #thenBlock
              ><img alt="bag icon" src="assets/images/icons/bag-white.svg"
            /></ng-template>
            <ng-template #elseBlock
              ><img alt="bag icon" src="assets/images/icons/bag.svg"
            /></ng-template>

            <span class="counter" *ngIf="this.showCount">
              {{ cartProductsCount$ | async }}
              <label class="visually-hidden" for="autoSizingInputGroup">
                items
              </label>
            </span>
          </span>


          Bag
          <label
            class="visually-hidden"
            for="autoSizingInputGroup"
            *ngIf="this.showCount"
          >
            with
          </label>
        </a>
      </div>
    </div>
  </div>
</div>
