import { CommonModule, NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IBreadcrumb } from '@ea/models';
import { BreadcrumbService } from '@ea/services';

@Component({
  selector: 'ea-breadcrumb',
  standalone: true,
  imports: [CommonModule, NgFor, RouterLink],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  constructor(private readonly breadcrumbService: BreadcrumbService) {
  }
  @Input() items: any;
}
