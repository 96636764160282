import { Injectable } from '@angular/core';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import environment from 'src/environments/environment';

@Injectable({
  providedIn: 'any',
})
export class StripeLoaderService {
  private stripePromise: Promise<Stripe | null>;

  constructor() {
    this.stripePromise = this.loadStripe();
  }

  private async loadStripe(): Promise<Stripe | null> {
    loadStripe.setLoadParameters({ advancedFraudSignals: false });
    return loadStripe(environment.stripe.key, { locale: 'en-GB' });
  }

  getStripeInstance(): Promise<Stripe | null> {
    return this.stripePromise;
  }
}
