<section class="m-2">
  <div class="container">
    <div class="row">

    <div *ngIf="cart; else skeleton">
      <div class="py-3 mt-5">
        <h2 class="font-bold font-title">Confirm delivery options</h2>
      </div>
      <ng-container *ngFor="let vendor of itemsByVendor">
        <div
          class="card font-title bg-theme-surface focus-outline text-theme-on-surface mb-4 vendor-card"
        >
        <p class="mb-2">Items from <span class="font-title font-bold vendor-name">{{ vendor.venorName }}</span></p>
        <hr>
          <ng-container *ngFor="let item of vendor.items">
            <div class="my-2">

              <div class="row mobile">
                <div class="cart-item-title d-flex flex-col justify-content-between h-100">
                  <div class="mb-2">

                  <h3> {{ item.extra.parentName.value }} </h3>
                  <span
                  *ngIf="item.title != item.extra.parentName.value"
                  [innerHTML]="getItemTitle(item)"
                ></span>
                  </div>
                  </div>
                <div class="col-5 mb-2">
                  <div class="cart-item-image">
                    <img
                      class="aspect-square object-cover"
                      alt="{{ item | attribute: 'image_alt_main' }}"
                      src="{{
                        item | attribute: 'image_main_product' | getImageFilter
                      }}"
                    />
                  </div>
                </div>
                <div class="col-7 mb-2  mt-5">

                    <div
                      class="cart-item-price d-flex align-items-end justify-content-start justify-content-end text-right mt-5"                  >
                      <span class="me-4">Quantity {{ item.quantity }}</span>
                      <span class="font-bold"
                        >{{
                          item.totalPrice.amount
                            | currency: item.totalPrice.currency
                        }}
                      </span>
                    </div>
                  </div>
                  <hr>
                </div>

              <div class="row discktop">
                <div class="col-lg-3 mb-2">
                  <div class="cart-item-image">
                    <img
                      class="aspect-square object-cover"
                      alt="{{ item | attribute: 'image_alt_main' }}"
                      src="{{
                        item | attribute: 'image_main_product' | getImageFilter
                      }}"
                    />
                  </div>
                </div>
                <div class="col-lg-9 mb-2">
                  <div class="cart-item-title d-flex flex-col justify-content-between h-100">
                    <div class="mb-2">

                    <h3> {{ item.extra.parentName.value }} </h3>
                    <span
                    *ngIf="item.title != item.extra.parentName.value"
                    [innerHTML]="getItemTitle(item)"
                  ></span>
                    </div>
                    <div
                      class="cart-item-price d-flex align-items-end justify-content-start justify-content-end"                  >
                      <span class="me-4">Quantity {{ item.quantity }}</span>
                      <span
                        >{{
                          item.totalPrice.amount
                            | currency: item.totalPrice.currency
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <hr>
                </div>
            </div>
          </ng-container>
          <ea-delivery-options
            (optionChanged)="onSelectedOptionIsChanged($event, vendor)"
            [vendorId]="vendor.vendorId"
            [vendor] ="vendor"
            [shipmentMethodId]="vendor.shipTo?.shipMethod?.shipMethodId"
          ></ea-delivery-options>
        </div>
      </ng-container>
    </div>
    <ng-template #skeleton>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'height.px': 100,
          'background-color': '#e4e4e4'
        }"
        [animation]="false"
      ></ngx-skeleton-loader>
    </ng-template>
  </div>
  </div>
</section>

