import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import environment from 'src/environments/environment';
import { Price } from 'src/app/models/productDetails.model';
import { BnplInfoComponent } from '../bnpl-info/bnpl-info.component';
import { MatIcon } from '@angular/material/icon';
declare var freshpaint: any;

@Component({
  selector: 'ea-item-price',
  standalone: true,
  imports: [CommonModule,BnplInfoComponent, MatIcon],
  templateUrl: './item-price.component.html',
  styleUrls: ['./item-price.component.scss'],
})
export class ItemPriceComponent {
  @Input() price?: Price | null;
  @Input() priceRange?: any | null;
  @Input() isChanged : boolean =false;
  @Input() productName : string='';
  @Input() isLike : boolean = false;
  @Output() triggerLikes: EventEmitter<any> = new EventEmitter<any>();
  showClearPayment =  false;
  installmentCount: number = 4;
  paymentAmount: number = 24.75;
  paymentProvider: string = 'Clearpay';
  showInfo: boolean = false;
  currency = environment.stripe.country == 'GB' ? '£' : '$';


  showClearPay(){
    freshpaint.track("click",{
      Page_type :"PDP",
      Page_name :this.productName,
      Click_name: "Button",
      Click_value :"click_clearpay"
    });
    this.showClearPayment = !this.showClearPayment;
  }
  triggerLike(){
    this.triggerLikes.emit();
  }
}
