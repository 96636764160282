<ng-container *ngIf="product">
  <div class="product-item">
    <a
      class="product-item-wrap"
      [routerLink]="['/product-details', product.sku]"
      (click)="trackGetProductEvent(product.sku)"
    >
      <img
        [src]="getTransformedImageUrl(product.image_main_product)"
        [alt]="product.image_alt_main || product.title"
      />
      <div class="card bg-theme-surface text-theme-on-surface">
        <div class="product-title">
          <h2
            class="card-title mb-3"
            [innerHTML]="product.display_name || getTitle(product)"
          ></h2>
        </div>
        <div>
          <p class="text-theme-on-background">
            <ng-container *ngIf="product.maxPrice == product.minPrice">
              <span
                class="product-price"
                [ngClass]="{
                  'old-price':
                    product.sale > 0 || product.prices?.GBP?.hasSalePrice
                }"
                [attr.aria-label]="
                  product.sale > 0 || product.prices?.GBP?.hasSalePrice
                    ? 'Sale price reduced from ' +
                      (product.price | currency: 'GBP') +
                      ' To ' +
                      (product.sale | currency: 'GBP')
                    : 'Price is ' + (product.price | currency: 'GBP')
                "
              >
                {{
                  product.price || product.prices?.GBP?.base | currency: "GBP"
                }}
              </span>
              <span
                class="sale-price"
                *ngIf="product.sale > 0 || product.prices?.GBP?.hasSalePrice"
              >
                {{
                  product.sale || product.prices?.GBP?.sale | currency: "GBP"
                }}
              </span>
            </ng-container>
            <ng-container *ngIf="product.maxPrice != product.minPrice">
              <span class="product-price"
                [attr.aria-label]="
                  'Max price is ' +
                  (product.maxPrice | currency: 'GBP') +
                  ' and Minimum price is ' +
                  (product.minPrice | currency: 'GBP')
                "
              >
                {{ product.minPrice | currency: "GBP" }} -
                {{ product.maxPrice | currency: "GBP" }}
              </span>
            </ng-container>
          </p>

          <div class="product-sold-by">
            <p class="text-theme-on-background">
              Sold by
              <span class="font-bold" [innerHtml]="product.vendor_name"></span>
            </p>
          </div>
        </div>
        <!-- Uncomment and implement the button functionality as needed -->
        <!--
      <button
        *ngIf="false"
        style="width: 100%"
        (click)="addToBasket()"
        class="submit ea-button ea-button-primary focus-ring height mt-4"
      >
        ADD TO BASKET
      </button>
      --></div>
    </a>
  </div>
</ng-container>
