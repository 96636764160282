import { CommonModule, NgIf } from '@angular/common';
import { Component, input, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { ICategory, IProductSearchResult } from '@ea/models';
import { FilterImagePipe } from '../../pipes';
import { DataLayerService } from 'src/app/services/shared/WindowReference/DataLayerService';
import { AddItemRequest, CartFacade, CartResponse } from '@ea/cart';
import { BehaviorSubject, Observable } from 'rxjs';
import environment from 'src/environments/environment';
import { LocalStorageService } from '@ea/services';
import { SyneriseService } from 'src/app/modules/category/services/synerise.service';
declare let SR: any;

@Component({
  selector: 'ea-product-grid',
  standalone: true,
  imports: [CommonModule, RouterLink, FilterImagePipe, NgIf],
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ProductGridComponent implements OnInit {
  @Input() product?: any;
  @Input() category?: ICategory;
  sanitizeProduct!: IProductSearchResult;
  @Input()  correlationId? : string
  @Input() index? :number;
  @Input() clientUUID? :string;

  sanitizedContent!: SafeHtml;
  @Input() itemId?: any;
  @Input() breadcrumbsString?: any;
  isAuthenticated$?: Observable<boolean>;
  cart$ = new BehaviorSubject<CartResponse | undefined>(undefined);
  eventModel: any;
  productsInTheCartCount$?: Observable<number>;
  constructor(
    private sanitizer: DomSanitizer,
    private dataLayer: DataLayerService,
    private readonly facade: CartFacade,
    private storageService: LocalStorageService,
    private synriesService : SyneriseService
  ) {}
  public get cart() {
    return this.cart$.value;
  }

  ngOnInit() {
    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(
      this.sanitizeProduct?._highlightResult.vendor_name.value
    );
  }

  getTitle(product: IProductSearchResult): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      product._highlightResult.title.value
    );
  }

  cleanSku(st: string) {
    return st.replace(/<\/?[^>]+(>|$)/g, '');
  }

  trackGetProductEvent(sku: string) {

    this.synriesService.itemClickedInSearch({
      correlationId:this.correlationId,
      clientUUID: this.clientUUID,
      position: this.index! +1,
      searchType: "full-text-search",
      item: sku
    }).subscribe(); 
    SR.event.trackCustomEvent('getProducts.action', {
      sku: sku,
    });
  }
  addToBasket() {
    if (this.product) {
      const request: AddItemRequest = {
        cartId: this.storageService.getItem('cartId'),
        name: this.storageService.getItem('meta-name'),
        items: [
          {
            itemId: this.itemId,
            type: 'WEB_SHIP',
            priceListId: environment.fabricPriceListId.priceListId,
            price: {
              amount:
                this.product.prices.GBP.sale ?? this.product.prices.GBP.base,
              currency: this.product.prices.GBP.currency,
            },
            quantity: 1,
            extra: {
              parentName: {
                attributeId: 'parent_Name',
                name: 'parent_Name',
                description: 'parent_Name',
                type: 'TEXT',
                value: this.product._highlightResult.title.value,
                price: 0,
              },
              breadcrumbsString: {
                attributeId: 'breadcrumbsString',
                name: 'breadcrumbsString',
                description: 'breadcrumbsString',
                type: 'TEXT',
                value: this.breadcrumbsString,
                price: 0,
              },
              orginalPrice: {
                attributeId: '',
                name: '',
                description: '',
                type: '',
                value: '',
                price: 0,
              },
            },
          },
        ],
      };

      this.sendGoogleEvent();
      this.facade.addItem(request);
      this.eventModel.quantity = 1;
      this.eventModel.event = 'add_to_cart';
      this.dataLayer.SendEvent(this.eventModel);
    }
  }
  sendGoogleEvent() {
    let x: any = {
      event: 'item_view',
      ecommerce: {
        item_name: this.product?.display_name,
        item_id: this.product?._highlightResult.sku,
        item_price:
          this.product!.prices.GBP.sale ?? this.product!.prices.GBP.base,
        item_brand: this.product!.vendor_name,
        quantity: 1,
        item_variant: this.product?.display_name,
      },
    };
    this.eventModel = x;
  }
  private cloudinaryBaseUrl =
    'https://res.cloudinary.com/revcascade/image/upload/';
  private transformParams = 'if_ar_gt_3:5/c_pad,h_700,w_500,b_white/if_else/c_fill,h_700,w_500/if_end'; // Set desired width, height, and crop mode

  getTransformedImageUrl(fullUrl: string): string {
    // Extract the public ID from the full Cloudinary URL
    if (fullUrl) {
      let publicId = fullUrl.split('/').slice(7).join('/'); // Extract public ID from the URL path
      if (!publicId) {
        publicId = fullUrl.split('/').slice(5).join('/'); // Extract public ID from the URL path
      }
      return `${this.cloudinaryBaseUrl}${this.transformParams}/assets.revcascade.com/${publicId}`;
    } else {
      return 'assets/images/placeholder.png';
    }
  }
}
