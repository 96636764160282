<section *ngIf="isLoaded">
  <div [ngClass]="isBulling? 'container-w' : 'container'">
    <div class="row">
      <div>
        <ea-selected-address   [address]="selectedAddress" [addresses]="addresses"(onAddressSelected)="onAddressSelected($event)"(goToDelevery)="goToDelevery()"
         (useThisAddressAsBilling)="useAsBillingAddress()" [isBulling]="isBulling" [bullingAddress]="bullingAddress">
        </ea-selected-address>
      </div>
    </div>
  </div>
</section>
