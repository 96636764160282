import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '@ea/services';
import { BaseComponent } from '../base.component';
declare var freshpaint: any;
@Component({
  selector: 'ea-search-bar',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent extends BaseComponent implements OnInit {
  constructor(
    private router: Router,
    private searchService: SearchService,
    private route: ActivatedRoute,
  ) {
    super();
  }
  @Input() isSmallScreen: boolean = false;
  inputId: string = '';
  public searchValue!: string;

  ngOnInit(
    number: number = this.subscriptions.push(
      this.route.queryParams.subscribe((queryParams) => {
        const searchQueryParamValue = queryParams['search'];
        this.searchValue = searchQueryParamValue;
        if (searchQueryParamValue) {
          this.setInitialSearchData(searchQueryParamValue);
        }
      }),
    ),
  ) {
    this.inputId = this.isSmallScreen ? 'small-search-input' : 'search-input';
  }

  setInitialSearchData(searchTerm: string) {
    const existingData = this.searchService.getData();
    if (!existingData) {
      this.searchService.setData(searchTerm);
    }
  }

  
  search() {
    freshpaint.track("search", {
      search_term :this.searchValue
        });
        
    freshpaint.track("click",{
      Page_type :"header",
      Page_name :"header",
      Click_name: "Button",
      Click_value :"search"
    });
    this.searchService.setData(this.searchValue);
    const queryParams = { search: this.searchValue };
    this.router.navigate(['/search-results'], { queryParams });
  }
}
