import { Injectable } from '@angular/core';
import {
  CardItem,
  CreatePaymentMethod,
  IntentResponse,
  WithIntentRequest,
  WithIntentRequestClearPay,
  createIntentRequest,
} from 'src/app/modules/cart/models/cart.model';
import environment from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import { LocalStorageService } from '../storage';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  baseUrl = '/Stripe/';
  createPaymentIntent(request: WithIntentRequest) {
    return this.api.post<IntentResponse>(
      `${this.baseUrl}Payment/MethodWithIntent/Create?region=${environment.stripe.country}`,
      request
    );
  }

  createPaymentIntentClearPAy(request: WithIntentRequestClearPay) {
    return this.api.post<IntentResponse>(
      `${this.baseUrl}Payment/MethodWithIntentClearPay/Create?region=${environment.stripe.country}`,
      request
    );
  }
  GetUserCards(customerId: string) {
    return this.api.get<CardItem[]>(
      `${this.baseUrl}Payment/Method/${customerId}?region=${environment.stripe.country}`
    );
  }

  deleteCart(id: string) {
    const userId = this.storage.getItem('user').userId;
    return this.api.delete<boolean>(
      `${this.baseUrl}Payment/Method/Detach/${userId}/${id}?region=${environment.stripe.country}`
    );
  }

  createPaymentIntentOnly(request: createIntentRequest) {
    return this.api.post<IntentResponse>(
      `${this.baseUrl}Payment/Intent/Create?region=${environment.stripe.country}`,
      request
    );
  }

  updatePaymentMethod(request: any) {
    return this.api.post<boolean>(
      `${this.baseUrl}update-expiration-date?region=${environment.stripe.country}`,
      request
    );
  }
  createPaymentMethod(request: CreatePaymentMethod) {
    return this.api.post<any>(
      `${this.baseUrl}Payment/Method/Create?region=${environment.stripe.country}`,
      request
    );
  }

  constructor(private api: ApiService, private storage: LocalStorageService) {}
}
