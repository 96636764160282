import { Injectable } from '@angular/core';
import { BehaviorSubject, skip } from 'rxjs';
import { LocalStorageService } from '../storage';

@Injectable({
  providedIn: 'root',
})
export class FilteredImageService {
  imageParams = 'b_transparent,c_pad,ar_4:5,w_700/q_auto,f_auto/';
  searchFilter = new BehaviorSubject<string>(this.imageParams);

  constructor(private storage: LocalStorageService) {
    this.searchFilter.pipe(skip(1)).subscribe((value) => {
      // set it in localstorage
      this.storage.setItem('searchFilter', value);
    });
  }
}
