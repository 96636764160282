import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthFacade } from '@ea/auth';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BaseComponent, ToastComponent } from '@ea/components';
import { Patterns } from '@ea/utils';
import { Toast } from '@ea/models';
import { ToastService } from '@ea/services';

@Component({
  selector: 'ea-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    ToastComponent,
  ],
})
export class ResetPasswordPageComponent
  extends BaseComponent
  implements OnInit
{
  isLoading$?: Observable<boolean> = this.auth.isLoading$;
  token: any;
  form: FormGroup;
  passwordVisible: boolean = false;
  confirmPasswordVisible = false;
  toast?: Toast;
  closeToast() {
    this.toast = undefined;
  }

  constructor(
    private builder: FormBuilder,
    private auth: AuthFacade,
    private route: ActivatedRoute,
    private toasterService: ToastService,
  ) {
    super();
    this.form = this.builder.group({
      email: ['', [Validators.required, Validators.email]],
      newPassword: [
        '',
        [Validators.required, Validators.pattern(Patterns.password)],
      ],
      confirmPassword: [
        '',
        [Validators.required, Validators.pattern(Patterns.password)],
      ],
    });
    this.toasterService._accountToast$.subscribe((toast) => {
      this.toast = toast;
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      this.token = paramMap.get('token');
    });
  }

  passwordsMatch(): boolean {
    return this.form.value.newPassword === this.form.value.confirmPassword;
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  submit() {
    const payload = {
      token: this.token,
      email: this.form.value.email,
      password: this.form.value.newPassword,
    };
    this.auth.reset(payload);
  }
}
