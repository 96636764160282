<ng-container *ngIf="control && !expiredDate">
  <label [for]="name" class="ea-label form-label">
    {{ label }} <span class="required" *ngIf="isRequired">(Required)</span>
  </label>
  <input
    type="text"
    class="ea-input w-100 focus-ring"
    [id]="name"
    [formControl]="control"
    [placeholder]="label"
    [alt] = 'alt'
  />
</ng-container>

<ng-container *ngIf="control && expiredDate">
  <label [for]="name" class="ea-label form-label">
    {{ label }} <span class="required" *ngIf="isRequired">(Required)</span>
  </label>
  <input
    type="text"
    class="ea-input w-100 focus-ring"
    [id]="name"
    [formControl]="control"
    [placeholder]="'MM/YY'"
    [alt]="alt"
    (input)="formatExpirationDate($event)"
  />
  
</ng-container>
