<div class="search-bar">
<!--  <div class="col-sm-12">-->
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <div class="search-box input-group pb-3 p-lg-0">
            <label class="visually-hidden" [for]="inputId">Search</label>
            <input
              type="search"
              [(ngModel)]="searchValue"
              class="ea-input form-control-flex font-title"
              [id]="inputId"
              placeholder="Search for products"
              (search)="search()"
              (keyup.enter)="search()"
              alt="search input"
              #input
            />

            <button
              (click)="search()"
              class="px-3 focus-ring d-flex align-items-center"
            >
              <span class="material-icons"
                >search
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
<!--  </div>-->
</div>
