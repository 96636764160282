import { CommonModule, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IOrder, IUser } from '@ea/models';
import { OrderService } from 'src/app/services/shared/orders';
import { OrderDetailsPageComponent } from '../order-details-page/order-details-page.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BaseComponent } from '@ea/components';
import { FilterImagePipe } from 'src/app/modules/products/pipes';
import { AuthFacade } from '@ea/auth';
import { Observable, switchMap } from 'rxjs';

@Component({
  selector: 'ea-order-page',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    RouterLink,
    OrderDetailsPageComponent,
    FilterImagePipe,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss'],
})
export class OrderPageComponent extends BaseComponent implements OnInit {
  orders: IOrder[] = [];
  loader: boolean = false;
  user$: Observable<IUser>;
  @Output() goToOrderDetails: EventEmitter<any> = new EventEmitter<any>();
  ordersWithItems : any;
  constructor(
    private orderService: OrderService,
    private facade: AuthFacade,
    private router: Router,

  ) {
    super();
    this.user$ = this.facade.user$;
  }
  goToOrderDetailsEvent( orderId : any){
    this.goToOrderDetails.emit(orderId);
  }
  ngOnInit(): void {
    this.loader = true;
    this.subscriptions.push(
      this.user$
      .pipe(
        switchMap((user) => {
          this.loader = true;
          return this.orderService.getOrderByCustomerId(user.customId);
        }),
      )
      .subscribe((response) => {
        this.loader = false;
        this.orders = response.orders;
    
        // Process each order
        this.orders = this.orders.map(order => {
          const groupedItems = order.items.reduce((acc: any, item) => {
            // Group by status
            const status = item.lineOrderStatus.toLowerCase();
            const capitalizedStatus = this.getStatusWord(status.charAt(0).toUpperCase() + status.slice(1));
    
            // If the status group doesn't exist, create it
            if (!acc[capitalizedStatus]) {
              acc[capitalizedStatus] = {};
            }
    
            // Group by vendorName within the status
            const vendorName = item.vendorName;
    
            // If the vendor group doesn't exist within the status, create it
            if (!acc[capitalizedStatus][vendorName]) {
              acc[capitalizedStatus][vendorName] = [];
            }
    
            // Add the item to the appropriate status and vendor group
            acc[capitalizedStatus][vendorName].push(item);
    
            return acc;
          }, {});
    
          return {
            ...order,
            groupedItems: groupedItems // Add the groupedItems to the order object
          };
        });
      })    
    );
  }


  getStatusWord(status : string){
      if(status == "Shipped"){
        return "Dispatched by";
      }
      else if(status == "Allocated"){
        return "In review with";
      }
      else if(status == "Cancelled"){
        return "Cancelled for"
      }
      else{
        return status
      }
  }
}
