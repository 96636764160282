<div class="d-block d-md-none subtotal-content-mobile col-lg-12">
  <ng-container *ngIf="cart$ | async as cart">
    <ng-container *ngIf="cart.totalItems > 0">
      <div>
        <div class="justify-content-between row" *ngIf=" cart.allPromosApplied">
          <div class="col">
           <span style="font-size:15px ;" *ngIf=" cart.allPromosApplied">Promotion applied</span>
      
          </div>
          <div class="col text-right ">
            <button
            class="remove-btn"
            (click)="removePromo()"
          >
            <mat-icon>close</mat-icon> Remove
          </button>
          </div>
        </div>
        <div class="justify-content-between row" *ngIf=" cart.allPromosApplied">
          <div class="col">
            <span *ngFor="let p of cart.allPromosApplied;  let i = index">
              @if(p.promoCode){
              {{p.promoCode}} 
              }
             @else{
                {{cart.promotionMessages[0].message}}
              }
              @if(i != cart.allPromosApplied.length -1){
                -
              }
            </span>
      
          </div>
          <div class="col text-right ">
            <h2> - {{ cart.totalDiscount | currency: cart.currency }}</h2>
          </div>
        </div>

        <div class="justify-content-between row">
          <div class="col">
            <span>Bag( {{ cart.totalItems }} items)</span>
          </div>
          <div class="col text-right total-price">
                      <span>{{
                          total$ | async | currency: cart.currency
                        }}</span>
          </div>
        </div>

        <!-- <div>
         <button (click)="triggerPromoCode()" class="remove-btn"><u>Apply a promotion <mat-icon>arrow_drop_down</mat-icon>
         </u> </button> 
         <div *ngIf="showPromoCode">
               <ng-container *ngIf="cart$ | async as cart ">
              <ng-container *ngIf="cart.totalItems > 0 && cart.allPromosApplied == null">
                  <div
                    class="total-card mobile-view mobile"
                  >
                    <div class="row ps-2 pe-2 cart-totals">
                    
                   <input class="input-text" type="text" placeholder="Promotion code or coupon" [(ngModel)]="promo">
                    </div>
                    <div class="row ps-3 pe-3 mt-4">
                      <button
                        (click)="applyPromoCode(cart)"
                        class="ea-button ea-button-primary  w-100 p-1 font-title font-bold"
                        [disabled]="!promo || (isButtonsDisabled$ | async)"
                      >
                       Apply
                      </button>
                    </div>
                  </div>
             </ng-container>
             </ng-container>
            </div>
        </div> -->
        <div class="row delevery-button mt-4">
          <button
            (click)="gotoCheckout()"
            [ngClass]="isAuthPage?   'ea-button ea-button-stroked  p-1 font-title font-bold' :' ea-button ea-button-primary p-1 font-title font-bold'"
            [disabled]="(isButtonsDisabled$ | async) || isAuthPage"
          >
            Confirm delivery
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
