<aside class="sidebar">
  <div class="text-center">
    <span class="material-icons main-icon">person</span>
    <h2>Your account</h2>
  </div>

  <nav class="mt-3 ps-3">
    <ul>
      <li>
        <a [routerLink]="'account-details'" routerLinkActive="active"
          >Personal details</a
        >
      </li>
      <li class="d-flex justify-content-between">
        <a [routerLink]="'orders'" routerLinkActive="active">Your orders</a>
        <span>
           {{this.orderCount }}
           </span>
      </li>
      <li class="d-flex justify-content-between">
        <a [routerLink]="'addresses'" routerLinkActive="active"
          >Your addresses</a
        >
        <span>{{countAddresses}}</span>
      </li>
      <li class="d-flex justify-content-between">
        <a [routerLink]="'my-cards'" routerLinkActive="active"
          >Your payment cards</a
        >
        <span>{{this.cardsNum}} </span>
      </li>
      <li class="d-flex justify-content-between">
        <a [routerLink]="'my-favorite-products'" routerLinkActive="active"
          >Favourite products
        </a>
        <span>
          <mat-icon class="like">favorite</mat-icon> {{ this.countProducts }}
        </span>
      </li>
      <li>
        <a [routerLink]="'charities'" routerLinkActive="active"
          >Preferred charity</a
        >
      </li>
      <li><span (click)="changePassword()">Change password</span></li>
      <li><span (click)="logout()">Log out</span></li>
    </ul>
  </nav>
</aside>
