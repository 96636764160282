<div class="mt-4" *ngIf="isDeliveryCompante">
  <div *ngIf="address">

    <div class="bg-theme-surface text-theme-on-surface mb-3 font-title mb-4 address-card"
      *ngIf="currentCompnant == 0" >
      <h2 *ngIf="!isBulling" class="font-bold">Your selected delivery address</h2>
      <h2 *ngIf="isBulling" class="font-bold">Your selected billing address</h2>
      <div *ngIf="address" class="p-0 card bg-theme-surface text-theme-on-surface">
        <div class="justify-content-between row">
          <div class="col">
            <p>{{ address.first }} {{ address.last }}</p>
            <p>{{ address.phone.number }}</p>
            <p class="address-line">
              {{ transform(address.addressLine1) }}, @if(address.addressLine2){
              <br /><label *ngIf="address.addressLine2">{{ transform(address.addressLine2) }},</label>
              } @if(address.addressLine3){
              <br />
              <label>{{ transform(address.addressLine3) }},</label>
              }
              <br />
              {{ transform(address.city) }}
              ,

              <br />{{ transform(address.county) }} <br />{{
              address.postalCode
              }}
              <br />
              @if(address.country.toUpperCase() != 'GB'){
              {{ address.country.toUpperCase() }}
              } @else{ UK }
            </p>

            <div class="row address-actions-mobile mt-4 w-100">
              <label for="addressAction">Actions</label>
              <select class="form-select border-gray-2px p-2  mb-2 ea-form focus-outline text-theme-on-surface w-100"
                [(ngModel)]="currentCompnant" id="addressAction">
                <option [ngValue]="1">
                  Edit this address
                </option>
                <option [ngValue]="2">
                  Select from address book
                </option>
                <option [ngValue]="3">
                  Add new address
                </option>
              </select>


            </div>
            <div class="mt-2 w-100" *ngIf="!isBulling">
              <input type="checkbox" class="form-check-input mr-2" [checked]="true" (click)="useAsBillingAddress()" id="useBillingAddress"/>
              <label class="form-check-label mt-1" for="useBillingAddress"> 
                Use this address as my billing address
              </label>
            </div>
            <div class="mt-2 address-actions-mobile" *ngIf="!isBulling">

              <!-- <div class="row" *ngIf="this.useingDeliveryAsBilling ">
                <button [routerLink]="['/payment']"
                   title="Continue"
                    class="ea-button ea-button-primary  w-100 p-1 "
                    aria-label="Confirm delivery address">
                    Confirm delivery address
                </button>
            </div>

            <div *ngIf="!this.useingDeliveryAsBilling">
                <button [routerLink]="['/payment']"
                    [queryParams]="{billing: address.addressId}"
                    title="Continue" class="ea-button ea-button-primary w-100 p-1"
                    aria-label="Continue to payment">
                    Confirm delivery address
                </button>

            </div> -->

              <button (click)="goToDeliveryOption()" title="Continue" class="ea-button ea-button-primary  p-1 "
                aria-label="Continue to payment">
                Confirm delivery address
              </button>



            </div>
            <div class="address-actions-mobile mt-4 w-100"  *ngIf="!isBulling">
              <div class="step-container">
                <div class="step-icon">
                  <img src="assets/images/icons/delivery.svg" alt="Delivery Icon">
                </div>
                <div class="step-text">

                  Step 2 - Delivery options
                </div>
              </div>

              <div class="step-container mt-3">
                <div class="step-icon">
                  <span class="material-icons">lock</span>
                </div>
                <div class="step-text">
                  Step 3 - Secure payment
                </div>
              </div>

            </div>
          </div>
          <div class="col text-right address-actions">
            <button class="m-2" (click)="chnageComponant(1)">
              <u> Edit this address </u>
            </button>
            <br />
            <button class="m-2" (click)="chnageComponant(2)">
              <u>Select from address book</u></button><br />
            <button class="m-2" (click)="chnageComponant(3)">
              <u>Add new address</u>
            </button>
          </div>
        </div>
      </div>

      <div class="row p-1">
        <ng-content></ng-content>
      </div>
    </div>
    <ea-address-editor *ngIf="currentCompnant == 1 && address" [address]="address" [isCreate]="address.addressId == bullingAddress"
      (dispose)="updateAddress($event)"></ea-address-editor>
  </div>

  <div class="p-4 bg-theme-surface text-theme-on-surface mb-3 font-title mb-4"
  *ngIf="currentCompnant == 4" >
    <div class="container">
      Use delivery address for billing address
      <div class="mt-2">
        <label class="mr-4">
          <input class="form-radio-input mr-2" type="radio" name="options" [(ngModel)]="bullingAddress" id="yes"
                 [value]="bullingAddress" style="width: 20px; height: 20px;"> <label for="yes"> 
        </label> <span>Yes</span></label>

        <label>
          <input class="form-radio-input mr-2" type="radio" style="width: 20px; height: 20px;" name="options" [(ngModel)]="bullingAddress" id="no"
                 [value]="'no'" (click)="chnageComponant(0)">
        </label> <label for="no"><span class="mb-4">No</span></label>
      </div>
    </div>
  </div>
  <div *ngIf="currentCompnant == 2 || ( !address && availableAddresses.length >0)">
    <div
      class="p-5 bg-theme-surface text-theme-on-surface mb-3 font-title mb-4 address-card justify-content-between row">
      <div class=" justify-content-between row">
        <div class="col-9">
          <h2>Select delivery address</h2>
        </div>
        <div class="col-3 text-right mt-4">
          <button (click)="chnageComponant(0)" class="remove-btn" style="font-size: 15px;">
            <u> <mat-icon>close</mat-icon> Cancel</u>
          </button>
        </div>
      </div>


      <div *ngFor="let addressOption of availableAddresses" [ngClass]="
          address != addressOption
            ? 'col address-option'
            : 'col address-option div-active'
        ">
        <div class="contaner justify-content-between row">
          <div class="col-1">
            <label>
              <input class="form-radio-input mr-2" type="radio" name="options" [(ngModel)]="address" id="{{address.addressId}}"
                [value]="addressOption" (ngModelChange)="onAddressSelecteds(addressOption)" />
            </label>
          </div>
          <div class="col-10">
            @if(addressOption.additionalAttributes.nickName){
            <h1>          <label for="{{address.addressId}}">{{ addressOption.additionalAttributes.nickName }} </label></h1>
            } @else{
            <h1>          <label for="{{address.addressId}}">{{ addressOption.first }}</label></h1>
            }
            <hr />
            <div class="mt-2">
              <p>{{ addressOption.first }} {{ addressOption.last }}</p>
              <p>{{ addressOption.phone.number }}</p>
              <p class="address-line">
                {{ transform(addressOption.addressLine1) }},
                @if(addressOption.addressLine2){ <br /><label *ngIf="addressOption.addressLine2">{{
                  transform(addressOption.addressLine2) }},</label>
                } @if(addressOption.addressLine3){
                <br />
                <label>{{ transform(addressOption.addressLine3) }},</label>
                }
                <br />
                {{ transform(addressOption.city) }}
                ,

                <br />{{ transform(addressOption.county) }} <br />{{
                addressOption.postalCode
                }}
                <br />
                @if(addressOption.country.toUpperCase() != 'GB'){
                {{ addressOption.country.toUpperCase() }}
                } @else{ UK }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ea-address-editor *ngIf="currentCompnant == 3 ||( !address && availableAddresses.length ==0)"
    (dispose)="updateAddress($event)" [fromCheckoutPage]="true" [isBilling]="isBulling"></ea-address-editor>
</div>
