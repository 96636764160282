import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../guards/auth.guard';
import { inject, NgModule } from '@angular/core';
export const ACCOUNT_ROUTES: Routes = [
  {
    path: 'account',
    title: 'My Account | EnableAll',
    data: { title: 'My Account' },
    canActivate: [() => inject(AuthGuard).canActivate()],
    loadComponent: () =>
      import('./pages/account-page/account-page.component').then(
        (x) => x.AccountPageComponent,
      ),
    children: [
      { path: '', redirectTo: 'account-details', pathMatch: 'full' },
      {
        path: 'account-details',
        title: 'My Details | EnableAll',
        data: { title: 'My Details' },
        loadComponent: () =>
          import('./pages').then((x) => x.AccountDetailsPageComponent),
      },
      {
        path: 'change-password/:token',
        title: 'Change password | EnableAll',
        data: { title: 'Change password' },
        loadComponent: () =>
          import('../auth/pages').then((x) => x.ResetPasswordPageComponent),
      },
      {
        path: 'my-cards',
        title: 'My Cards | EnableAll',
        data: { title: 'My Cards' },
        loadComponent: () =>
          import('./pages').then((x) => x.MyCardsPageComponent),
      },
      {
        path: 'my-favorite-products',
        title: 'My favorite | EnableAll',
        data: { title: 'My Favorite' },
        loadComponent: () =>
          import('./pages/productLikes-page/productliks-page.component').then(
            (x) => x.ProductliksPageComponent,
          ),
      },
      {
        path: 'charities',
        title: 'charities | EnableAll',
        data: { title: 'charities' },
        loadComponent: () =>
          import('./pages').then((x) => x.CharityPageComponent),
      },
      {
        path: 'orders',
        data: { title: 'Orders' },
        children: [
          {
            path: '',
            title: 'My Orders | EnableAll',
            loadComponent: () =>
              import('./pages').then((x) => x.OrderPageComponent),
          },
          {
            title: 'Order Details | EnableAll',
            data: { title: 'Order Details' },
            path: 'order-details/:orderId',
            loadComponent: () =>
              import('./pages').then((x) => x.OrderDetailsPageComponent),
          },
          {
            title: 'Order Return | EnableAll',
            data: { title: 'Order Return' },
            path: 'order-return/:orderId/:orderItemId',
            loadChildren: () =>
              import(
                /* webpackChunkName: "order-return" */ '../../modules/account/pages/order-return-page/modules/order-return.module'
              ).then((m) => m.OrderReturnModule),
          },
        ],
      },
      {
        path: 'addresses',
        data: { title: 'My Addresses' },
        children: [
          {
            path: '',
            title: 'My Addresses | EnableAll',
            loadComponent: () =>
              import('./pages').then((x) => x.AddressesPageComponent),
          },
          {
            title: 'Add New Address | EnableAll',
            data: { title: 'Add New Address' },
            path: 'new-address',
            loadComponent: () =>
              import('../address-editor/components').then(
                (x) => x.AddressEditorComponent,
              ),
          },

          {
            title: 'Edit Address | EnableAll',
            data: { title: 'Edit Address' },
            path: 'edit-address/:id',
            loadComponent: () =>
              import('../address-editor/components').then(
                (x) => x.AddressEditorComponent,
              ),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ACCOUNT_ROUTES)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
