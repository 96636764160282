import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';

import { Patterns } from 'src/app/utils/patterns';
import { AuthFacade, CookieService } from '@ea/auth';
import { ToastService } from '@ea/services';
import { BaseComponent } from '@ea/components';
import {
  CountryISO,
  NgxIntlTelInputModule,
  PhoneNumberFormat,
  SearchCountryField,
} from '@justin-s/ngx-intl-tel-input';
import { CreateAccountFormComponent } from '../../components/create-account-form/create-account-form.component';

@Component({
  selector: 'ea-register',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    NgxIntlTelInputModule,
    CreateAccountFormComponent,
  ],
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent extends BaseComponent {
  // passwordVisible: boolean = false;
  // registerForm: FormGroup;
  // PhoneNumberFormat = PhoneNumberFormat;
  // SearchCountryField = SearchCountryField;
  // CountryISO = CountryISO;
  // preferredCountries: CountryISO[] = [
  //   CountryISO.UnitedStates,
  //   CountryISO.UnitedKingdom,
  // ];
  // separateDialCode = false;
  //
  // constructor(
  //   private builder: FormBuilder,
  //   private cookies: CookieService,
  //   private auth: AuthFacade,
  //   private toasterService: ToastService,
  // ) {
  //   super();
  //   this.registerForm = this.builder.group({
  //     uuid: [this.cookies.get('_snrs_uuid')],
  //     firstName: ['', [Validators.required]],
  //     lastName: ['', [Validators.required]],
  //     email: ['', [Validators.required, Validators.email]],
  //     phone: this.builder.control('', Validators.required),
  //     password: [
  //       '',
  //       [Validators.required, Validators.pattern(Patterns.password)],
  //     ],
  //   });
  // }
  //
  // togglePasswordVisibility() {
  //   this.passwordVisible = !this.passwordVisible;
  // }
  //
  // showValidationMessage(message: string) {
  //   this.toasterService.show(message);
  // }
  //
  // register() {
  //   if (this.isFormValid()) {
  //     this.toggleDisable(true);
  //     const payload = this.registerForm.value;
  //     this.auth.register(payload);
  //     this.toggleDisable(false);
  //   }
  // }
  //
  // isFormValid() {
  //   if (this.registerForm.status == 'INVALID') {
  //     if (this.registerForm.controls['phone'].status == 'INVALID') {
  //       if (this.registerForm.controls['phone'].value.internationalNumber) {
  //         return true;
  //       } else {
  //         this.showValidationMessage('Please enter your Phone number');
  //       }
  //     }
  //     if (this.registerForm.controls['firstName'].status == 'INVALID') {
  //       this.showValidationMessage('Please enter your First name');
  //     } else if (this.registerForm.controls['lastName'].status == 'INVALID') {
  //       this.showValidationMessage('Please enter your Last name');
  //     } else if (this.registerForm.controls['email'].status == 'INVALID') {
  //       this.showValidationMessage('Please enter your Email');
  //     } else if (this.registerForm.controls['password'].status == 'INVALID') {
  //       this.showValidationMessage(
  //         "Password must be minimum of 8 characters and contain at least 1 uppercase letter, 1 number and 1special character @$!%*?&#-_.'",
  //       );
  //     }
  //   }
  //
  //   return this.registerForm.status == 'VALID';
  // }
}
