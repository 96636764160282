import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CartState } from './cart.interface';

export const FEATURE_NAME = 'cart';
const getState = createFeatureSelector<CartState>(FEATURE_NAME);

const isLoading = createSelector(getState, (state) => state.isLoading);
const isLoaded = createSelector(getState, (state) => state.isLoaded);
const getCart = createSelector(getState, (state) => state.cart);
const getItemsInTheCart = createSelector(
  getState,
  (state) => state.cart.totalItems
);

const getItems = createSelector(getState, (state) => state.cart.items);

export const cartSelectors = {
  getCart,
  getItems,
  isLoading,
  isLoaded,
  getItemsInTheCart,
};
