<section>
  <div class="container">
    <!--create-account-->
    <div class="create-account">
      <div class="row">
        <div class="col-12">
          <div class="p-container">
            <h1 class="font-title">Create a new account</h1>
            <hr />
          </div>

            <legend>About you</legend>
            <ea-create-account-form
              [fromCartPage]="true"
            ></ea-create-account-form>
          <div class="p-container">
            <hr />
          </div>


            <legend class="mb-0 pb-0">Address</legend>
          <div class="p-container pt-0">
            <ea-address-editor *ngIf="RegisterComponent"
            [firstName]="RegisterComponent.registerForm.get('firstName')?.value"
            [lastName]="RegisterComponent.registerForm.get('lastName')?.value"
            [phone]="RegisterComponent.registerForm.get('phone')?.value"
             [fromCartPage]="true"></ea-address-editor>
          </div>
            <div class="text-right" *ngIf="(RegisterComponent && AddressEditorComponent)">
              <button (click)="createAccount()" class="ea-button ea-button-primary "

               [disabled]="(RegisterComponent.registerForm.invalid || AddressEditorComponent.newAddressForm.invalid) || (isButtonsDisabled$ | async)">
                Create account
              </button>
            </div>

        </div>
      </div>
    </div>
  </div>
</section>
