import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AlgoliaService } from 'src/app/services/shared/algolia/algolia.service';
import { ProductGridComponent } from '../product-grid/product-grid.component';
import { Observable } from 'rxjs';
import {
  AlgoliaSearchQuery,
  IProductSearchResult,
  IProductSearchResults,
} from 'src/app/models/product.model';
import { ProductDataService } from 'src/app/services/shared/product-data/product-data.service';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { BaseComponent } from '@ea/components';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import environment from 'src/environments/environment';

@Component({
  selector: 'ea-products',
  standalone: true,
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  imports: [
    CommonModule,
    ProductGridComponent,
    PaginationComponent,
    RouterLink,
    MatIconModule,
  ],
})
export class ProductsComponent extends BaseComponent implements OnInit {
  @Input() set query(value: AlgoliaSearchQuery) {
    this._categoryQuery = value;
  }
  @Input() isLikePages?: boolean;
  @Input() productslikes?: any[] = [];

  products$!: Observable<IProductSearchResults | null>;

  private _categoryQuery!: AlgoliaSearchQuery;
  private _searchQuery = '';
  private _pageNo = 0;
  categoryId: string = '';
  level: number = -1;
  displaynonCol = false;
  colsize: number = 4;
  colspaceh: number = 2;
  moredisable = false;
  lessdisable = false;
  colspacev: number = 0;
  lessdisablespaceh = false;
  moredisablespaceh = false;
  lessdisablespacev = false;
  moredisablespacev = false;
  sanitizeProduct!: IProductSearchResult;
  sanitizedContent!: SafeHtml;
  showlayout: boolean = false;
  constructor(
    private algoliaService: AlgoliaService,
    private productDataService: ProductDataService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    super();
    this.products$ = this.productDataService.products$;
  }
  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((queryParams) => {
        this._searchQuery = queryParams['search'];
        const n =  (queryParams['page'] -1 );
        this.route.params.subscribe((params) => {
          if (params['id']) {
            this.categoryId = params['id'];
            this.level = +params['l'];
          }
        });
        if (
          this._searchQuery ||
          this._categoryQuery ||
          (this._pageNo != n && n)
        ) {
          this._pageNo = (queryParams['page'] -1 ) || 0;
          this.searchProducts();
        }
        if (this.isLikePages) {
          this.searchProducts();
        }
      })
    );
  }

  changePage(page: number) {
    // change query params with page number
    this.router.navigate([], {
      queryParams: { page },
      queryParamsHandling: 'merge',
    });
  }

  searchProducts() {
    var filters = 'hasInventory:true';
    if (environment.base != 'uk') {
      filters += ' AND locale:en-us';
    } else {
      filters += ' AND locale:en-gb';
    }
    if (this.categoryId != '' && this.level != -1) {
      let priceRegion = environment.base == 'uk' ? 'prices.GBP' : 'prices.USD';
      const queryPayload: AlgoliaSearchQuery = {
        query: this._searchQuery,
        attributesToRetrieve: [
          'image_main_product',
          'image_alt_main',
          priceRegion,
        ],
        facetFilters: [
          'categoriesIds.lvl' + this.level + ':' + this.categoryId,
        ],
        hitsPerPage: 12,
        page: this._pageNo,
        filters: filters,
        typoTolerance: 'false',
      };
      const customQuery = {
        ...this._categoryQuery,
        page: this._pageNo,
      };
      this.subscriptions.push(
        this.algoliaService
          .search(this._categoryQuery ? customQuery : queryPayload) // if we pass custom query from the parent component it will prioritize that
          .subscribe((result: IProductSearchResults) => {
            this.productDataService.setProducts(result);
          })
      );
    } else if (this.isLikePages) {
      var facetFilters = '';
      if (this.productslikes) {
        var i = 0;
        this.productslikes!.forEach((element) => {
          if (i == this.productslikes?.length! - 1) {
            facetFilters = facetFilters + 'sku:' + element.sku;
          } else {
            facetFilters = facetFilters + 'sku:' + element.sku + ' OR ';
          }
          i++;
        });
      }

      const queryPayload: AlgoliaSearchQuery = {
        query: this._searchQuery,
        hitsPerPage: 12,
        page: this._pageNo,
        filters: facetFilters,
        typoTolerance: 'false',
      };
      const customQuery = {
        ...this._categoryQuery,
        page: this._pageNo,
      };
      this.subscriptions.push(
        this.algoliaService
          .search(this._categoryQuery ? customQuery : queryPayload) // if we pass custom query from the parent component it will prioritize that
          .subscribe((result: IProductSearchResults) => {
            this.productDataService.setProducts(result);
          })
      );
    } else {
      const queryPayload: AlgoliaSearchQuery = {
        query: this._searchQuery,
        hitsPerPage: 12,
        page: this._pageNo,
        filters: filters,
        typoTolerance: 'false',
      };
      const customQuery = {
        ...this._categoryQuery,
        page: this._pageNo,
      };

      this.subscriptions.push(
        this.algoliaService
          .search(this._categoryQuery ? customQuery : queryPayload) // if we pass custom query from the parent component it will prioritize that
          .subscribe((result: IProductSearchResults) => {
            this.productDataService.setProducts(result);
          })
      );
    }
  }
  more() {
    this.colsize++;
    this.checkColSize();
  }
  less() {
    this.colsize--;
    this.checkColSize();
  }

  checkColSize() {
    if (this.colsize == 1) {
      this.lessdisable = true;
    } else {
      this.lessdisable = false;
    }
    if (this.colsize == 6) {
      this.moredisable = true;
    } else {
      this.moredisable = false;
    }
  }

  lessspacev() {
    this.colspacev--;
    this.checkSpacev();
  }
  morespasev() {
    this.colspacev++;
    this.checkSpacev();
  }

  checkSpacev() {
    if (this.colspacev == 0) {
      this.lessdisablespacev = true;
    } else {
      this.lessdisablespacev = false;
    }
    if (this.colspacev == 4) {
      this.moredisablespacev = true;
    } else {
      this.moredisablespacev = false;
    }
  }

  lessspaceh() {
    this.colspaceh--;
    this.checkSpaceh();
  }
  morespaseh() {
    this.colspaceh++;

    this.checkSpaceh();
  }

  checkSpaceh() {
    if (this.colspaceh == 0) {
      this.lessdisablespaceh = true;
    } else {
      this.lessdisablespaceh = false;
    }
    if (this.colspaceh == 4) {
      this.moredisablespaceh = true;
    } else {
      this.moredisablespaceh = false;
    }
  }

  getTitle(product: IProductSearchResult): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      product._highlightResult.title.value
    );
  }

  show() {
    this.showlayout = !this.showlayout;
  }

  getItemId(product: any): string | undefined {
    const likedProduct = this.productslikes?.find(
      (x) => x.sku === product?._highlightResult?.sku?.value
    );
    return likedProduct?.itemId;
  }

  getBreadCrumbsString(product: any): string | undefined {
    const likedProduct = this.productslikes?.find(
      (x) => x.sku === product?._highlightResult?.sku?.value
    );
    return likedProduct?.breadcrumbsString;
  }
}
