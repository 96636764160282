import { Injectable } from '@angular/core';
import { TaxResponse } from 'src/app/modules/cart/models/cart.model';
import environment from 'src/environments/environment';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class VertexService {
  baseUrl = '/Vertex/Tax/';
  calculateTax(request: any) {
    return this.api.post<TaxResponse>(
      `${this.baseUrl}CalculateTaxOnProduct?region=${environment.stripe.country}`,
      request
    );
  }

  postTax(request: any) {
    return this.api.post<any>(
      `${this.baseUrl}PostTax?region=${environment.stripe.country}`,
      request
    );
  }

  constructor(private api: ApiService) {}
}
