import { CommonModule, NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Toast } from '@ea/models';
import { ToastService } from '@ea/services';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'ea-toaster',
  standalone: true,
  imports: [CommonModule, NgFor, ToastComponent],
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit, OnDestroy {
  private _toasts$: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);
  toasts$: Observable<Toast[]>;

  private unsubscribe$ = new Subject<void>();

  constructor(private toastService: ToastService) {
    this.toasts$ = this._toasts$.asObservable();
  }

  ngOnInit(): void {
    this.toastService.toast$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((toast) => {
        this._toasts$.next([...this._toasts$.value, toast]);
      });
  }

  remove(index: number) {
    const toasts = this._toasts$.value;
    toasts.splice(index, 1);
    this._toasts$.next(toasts);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
