import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouterLink } from '@angular/router';
import { NavCategoryItem } from 'src/app/models/category.model';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'ea-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterModule],
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent extends BaseComponent {
  @Input() categories?: NavCategoryItem[];
}
