import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { AuthLinksComponent } from 'src/app/modules/auth/auth-links/auth-links.component';
import { BaseComponent } from '../base.component';
import { NavCategoryItem } from '@ea/models';
import environment from 'src/environments/environment';
import { map, Observable, Subject, switchMap, timer } from 'rxjs';
import { CartResponse, cartSelectors } from '@ea/cart';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { CartStep, CartStepService } from '../../services/shared/cart-steps';

@Component({
  selector: 'ea-masthead',
  standalone: true,
  imports: [CommonModule, SearchBarComponent, RouterLink, AuthLinksComponent],
  templateUrl: './masthead.component.html',
  styleUrls: ['./masthead.component.scss'],
})
export class MastheadComponent extends BaseComponent implements OnInit {
  @Input() categories?: NavCategoryItem[];
  readMode: boolean = false;
  cart$?: Observable<CartResponse>;
  showSmallLogo = false;
  showTotal = false;
  showStep = false;
  stepData?: CartStep;
  private clickListener: () => void;
  private focusListener: () => void;
  private scrollListener: () => void;

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private router: Router,
    private cartStepService: CartStepService,
  ) {
    super();
    // Listen to click events on the whole document
    this.clickListener = this.renderer.listen('document', 'click', () => {
      this.showSmallLogo = true;
    });

    // Listen to focus events on the whole document
    this.focusListener = this.renderer.listen('document', 'focus', () => {
      this.showSmallLogo = true;
    });

    // Listen to focus events on the whole document
    this.scrollListener = this.renderer.listen('document', 'scroll', () => {
      this.showSmallLogo = true;
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.showTotal =
          this.router.url == '/checkout' ||
          this.router.url.split('?')[0] == '/payment';
        this.showStep =
          this.router.url == '/checkout' ||
          this.router.url.split('?')[0] == '/payment' ||
          this.router.url.split('?')[0] == '/cart';
      });
  }

  ngOnInit(): void {
    this.cart$ = this.store
      .select(cartSelectors.getCart)
      .pipe(map((cart) => cart));

    this.cartStepService.currentStep$.subscribe((data) => {
      this.stepData = data;
      //this.data = data;
    });

    this.showTotal =
      this.router.url == '/checkout' ||
      this.router.url.split('?')[0] == '/payment';
    this.showStep =
      this.router.url == '/checkout' ||
      this.router.url.split('?')[0] == '/payment' ||
      this.router.url.split('?')[0] == '/cart';
  }

  public get logoTitle() {
    const title = 'EnableAll Home';
    if (environment.production) {
      return `${title} ${environment.stripe.country}`;
    }

    return title;
  }

  openA11yWidget() {
    let element = document
      .getElementById('a11y-access-tool-general-button')
      ?.click();
  }

  scrollToStepContainer() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
