<section>
  <div [ngClass]="isLikePages ? '' :'container'">
    <ng-container *ngIf="products$ | async as products">
      <h3 class="font-bold" *ngIf="!isLikePages">
        {{ products.nbHits }} Results
      </h3>
      @if(false){
      <div class="text-end m-4">
        <button
          type="button"
          class="layout"
          (keydown)="show()"
          (click)="show()"
        >
          <mat-icon *ngIf="!showlayout">play_arrow</mat-icon>
          <mat-icon *ngIf="showlayout">arrow_drop_down</mat-icon> Layout Options
        </button>
      </div>
      <div class="row d-flex justify-content-end mb-3 px-4" *ngIf="showlayout">
        <div class="white-background layout-option m-1 py-3">
          <div class="m-auto px-2">
            <p class="head">Columns</p>
          </div>
          <div class="m-auto px-2">
            <button
              type="button"
              class="btn btn-outline-secondary m-auto b"
              [disabled]="this.lessdisable"
              (click)="less()"
            >
              LESS
            </button>
          </div>
          <div class="m-auto px-2">
            <button
              type="button"
              class="btn btn-outline-secondary m-auto b"
              [disabled]="this.moredisable"
              (click)="more()"
            >
              MORE
            </button>
          </div>
        </div>
        <div class="white-background layout-option m-1 py-3">
          <div class="m-auto px-2">
            <p>Horizontal space</p>
          </div>
          <div class="m-auto px-2">
            <button
              type="button"
              class="btn btn-outline-secondary m-auto b"
              [disabled]="this.lessdisablespaceh"
              (click)="lessspaceh()"
            >
              LESS
            </button>
          </div>
          <div class="m-auto px-2">
            <button
              type="button"
              class="btn btn-outline-secondary m-auto b"
              [disabled]="this.moredisablespaceh"
              (click)="morespaseh()"
            >
              MORE
            </button>
          </div>
        </div>
        <div class="white-background layout-option m-1 py-3">
          <div class="m-auto px-2">
            <p>Vertical space</p>
          </div>
          <div class="m-auto px-2">
            <button
              type="button"
              class="btn btn-outline-secondary m-auto b"
              [disabled]="this.lessdisablespacev"
              (click)="lessspacev()"
            >
              LESS
            </button>
          </div>
          <div class="m-auto px-2">
            <button
              type="button"
              class="btn btn-outline-secondary m-auto b"
              [disabled]="this.moredisablespacev"
              (click)="morespasev()"
            >
              MORE
            </button>
          </div>
        </div>
      </div>
   
    }
    <div
    [ngClass]="isLikePages 
      ? 'row d-flex row-flex col-10 ' 
      : 'row d-flex row-flex row-cols-md-' + colsize + 
        ' row-cols-sm-' + (colsize > 3 ? 3 : colsize) + 
        ' row-cols-1 col-container mt-4'"
  >
  
        <div
          *ngFor="let product of products.hits"
          class="col-6 p-{{ colspaceh }} mb-{{ colspacev }}"
        >
          <ea-product-grid
            *ngIf="isLikePages && product"
            [itemId]="getItemId(product)"
            [breadcrumbsString]="getBreadCrumbsString(product)"
            [product]="product"
            class="cols"

          ></ea-product-grid>
          <ea-product-grid
            *ngIf="!isLikePages"
            [product]="product"
            class="cols"
          ></ea-product-grid>
        </div>
      </div>
      <ea-pagination
        [currentPage]="products.page +1"
        [totalPages]="products.nbPages"
        (pageChange)="changePage($event)"
      ></ea-pagination>
    </ng-container>
  </div>
</section>
