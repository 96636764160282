import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { CharityData, CharityOrder } from 'src/app/models/CharityData ';

@Injectable({
  providedIn: 'root',
})
export class CharityService {
  constructor(private api: ApiService) {}

  getProfileDropDown(id: string): Observable<CharityData[]> {
    const body = {};
    return this.api.get(`/Charities/profile/` + id);
  }

  insertDefaultCharity(customId: string, CharityId: number) {
    return this.api.post(`/Charities/profile/${customId}/${CharityId}`, {});
  }

  getProfileDropDownCheckout(id: string): Observable<CharityData[]> {
    const body = {};
    return this.api.get(`/Charities/checkout/` + id);
  }

  attachCharityToOrder(request: CharityOrder) {
    return this.api.post<any>(`/Charities/order`, request);
  }
}
