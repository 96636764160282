import { Action, createReducer, on } from '@ngrx/store';
import { cartActions } from './cart.actions';
import { INITIAL_STATE, CartState } from './cart.interface';
import { CartResponse } from '../models';

const reducer = createReducer(
  INITIAL_STATE,

  on(cartActions.init, () => INITIAL_STATE),

  on(cartActions.set, (state, cart: CartResponse) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    cart: {
      ...state.cart,
      ...cart,
    },
  })),

  on(cartActions.find, (state) => ({
    ...state,
    isLoaded: false,
    isLoading: true,
  }))
);

export function cartReducer(state: CartState | undefined, action: Action) {
  return reducer(state, action);
}
