import { Component, input, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, Observable } from 'rxjs';
import { isAuthenticated } from '@ea/auth';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

export interface OrderSteps {
  Bag: string;
  Auth?: string;
  Delivery: string;
  Payment: string;
}

@Component({
  selector: 'ea-order-steps',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-steps.component.html',
  styleUrl: './order-steps.component.scss',
})
export class OrderStepsComponent implements OnInit {
  @Input() step?: string;
  @Input() isNoAddress : boolean = false
  
  isAuthenticated$ = new Observable<boolean>();
  steps: OrderSteps = {
    Bag: 'active',
    Auth: 'default',
    Delivery: 'default',
    Payment: 'disabled',
  };
  constructor(
    private store: Store,
    private router: Router,
  ) {}
  ngOnInit(): void {
    this.isAuthenticated$ = this.store
      .select(isAuthenticated)
      .pipe(map((state) => state));
    //set step
    if (this.step == 'Delivery') {
      this.steps = { Bag: 'done', Delivery: 'active', Payment: 'default' };
    }
 
    if (this.step == 'step2') {
      this.steps = { Bag: 'done', Delivery: 'done', Payment: 'default' };
    }


    if (this.step == 'Payment') {
      this.steps = { Bag: 'done', Delivery: 'done', Payment: 'active' };
    }

    if (this.step == 'Auth') {
      this.steps = {
        Bag: 'done',
        Auth: 'active',
        Delivery: 'default',
        Payment: 'disabled',
      };
    }
  }

  goToPage(page: string) {
    this.router.navigate([`/${page}`]);
  }
}
