import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WindowReferenceService } from './WindowReferenceService';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private window;

  constructor(
    _windowRef: WindowReferenceService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.window = _windowRef.nativeWindow;
    }
  }

  SendEvent(obj: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (obj) this.window.dataLayer.push(obj);
    }
  }
}
