<section>
  <div class="container">
    <div class="d-flex justify-content-between">
    <h1 class="d-none d-md-block">Reset password</h1>
    <div class="toast-div">
      <ea-toast
        *ngIf="toast"
        [toast]="toast"
        (dispose)="closeToast()"
      ></ea-toast>
    </div>
    </div>
    <form [formGroup]="form" class="ea-form rounded">
      <div class="flex flex-col gap-4">
        <div class="bg-theme-surface text-theme-on-surface rounded">
          <div class="ea-form-field">
            <label for="email-input" class="ea-label font-bold required"
              >Email address</label
            >
            <input
              id="email-input"
              formControlName="email"
              name="email"
              class="ea-input"
              type="email"
              placeholder="Email address"
              autocomplete="email"
              alt="email input"
            />
          </div>

          <div class="ea-form-field">
            <label class="ea-label font-bold required" for="newPassword">
              New password
            </label>

            <div class="input-group">
              <input
                [type]="passwordVisible ? 'text' : 'password'"
                formControlName="newPassword"
                placeholder="Password"
                class="ea-input form-control-flex focus-ring"
                id="newPassword"
                alt="new password input"
              />
              <div class="input-group-text">
                <button
                  type="button"
                  class="btn btn-dark m-0 p-0 focus-ring"
                  mat-icon-button
                  matSuffix
                  (click)="togglePasswordVisibility()"
                >
                  <span class="material-icons">{{
                      passwordVisible ? "visibility" : "visibility_off"
                    }}</span>
                </button>
              </div>
            </div>
            <div
              *ngIf="
                form.get('newPassword')?.touched &&
                form.get('newPassword')?.errors
              "
            >
              <span class="error">Passwords too weak</span>
            </div>

            <small class="text-theme-on-surface" style="color: #dc3545">
              Password must be at least 8 characters and contain the following:
              <br />
              <ul class="mt-3">
                <li>• 1 uppercase letter</li>
                <li>• 1 number</li>
                <li>• 1 of these special characters : {{ "@$%*?&#-_." }}</li>
              </ul>
            </small>

          </div>

          <div class="ea-form-field mb-0">
            <label class="ea-label font-bold required" for="password">
              Confirm password
            </label>

            <div class="input-group">
              <input
                [type]="confirmPasswordVisible ? 'text' : 'password'"
                formControlName="confirmPassword"
                placeholder="Password"
                class="ea-input form-control-flex focus-ring"
                id="password"
                alt="confirm password input"
              />
              <div class="input-group-text">
                <button
                  type="button"
                  class="btn btn-dark m-0 p-0 focus-ring"
                  mat-icon-button
                  matSuffix
                  (click)="confirmPasswordVisible=!confirmPasswordVisible"
                >
                  <span class="material-icons">{{
                      confirmPasswordVisible ? "visibility" : "visibility_off"
                  }}</span>
                </button>
              </div>
            </div>

            <div *ngIf="!passwordsMatch()">
              <span class="error">Passwords do not match</span>
            </div>
          </div>
        </div>
        <div class="px-4 lg:px-8">
          <input
            type="submit"
            class="ea-button ea-button-primary w-full"
            value="Submit"
            title="Submit"
            (click)="submit()"
            [disabled]="
              !passwordsMatch() || (isLoading$ | async) || form.invalid
            "
            alt="Submit"
          />
        </div>
      </div>
    </form>
  </div>
</section>
