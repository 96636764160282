<section class="py-5 container">
  <!--  Desktop-->
  <div class="row d-none d-md-flex">
    <div class="col-12 col-sm-4 col-lg-3">
      <ea-account-nav></ea-account-nav>
    </div>

    <section class="col-12 col-sm-8 col-lg-9">
      <router-outlet></router-outlet>
    </section>
  </div>
  <!--  End desktop-->

  <!--Mobile-->
<div class="row d-block d-md-none mobile">
    <div class="text-center">
      <h1><span class="material-icons main-icon">person</span>Your account</h1>
    </div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="">
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button"
            type="button"
            [class.collapsed]="!isRouteActive('/account-details')"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="true"
            aria-controls="flush-collapseOne"
            [routerLink]="['account-details']"
          >
          Personal Details
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          [class.show]="isRouteActive('/account-details')"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
        <div class="mt-3">
          <ea-account-details-page></ea-account-details-page>
        </div>
        </div>
      </div>
      <div class="">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button
            class="accordion-button"
            type="button"
            [class.collapsed]="!isRouteActive('/orders')"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="true"
            aria-controls="flush-collapseTwo"
            [routerLink]="['orders']"
          >
          Your Orders
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse"
          [class.show]="isRouteActive('/orders')"
          aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample"
        >
        @if(isRouteActive('order-details') || orderId){
          <ea-order-details-page [orderIdMobile]="orderId"></ea-order-details-page>
        }
        @else{
          <ea-order-page (goToOrderDetails)="goToOrderDetails($event)"></ea-order-page>
        }
      </div>
      </div>
      <div class="">
        <h2 class="accordion-header" id="flush-headingThree">
          <button
            class="accordion-button"
            type="button"
            [class.collapsed]="!isRouteActive('/addresses')"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree"
            aria-expanded="false"
            aria-controls="flush-collapseThree"
            [routerLink]="['addresses']"

          >
           Your Addresses
          </button>
        </h2>
        <div
          id="flush-collapseThree"
          class="accordion-collapse collapse"
          [class.show]="isRouteActive('addresses')"
          aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample"
        >
        <ea-addresses-page></ea-addresses-page>
      </div>
      </div>
      <div class="">
        <h2 class="accordion-header" id="flush-headingFour">
          <button
            class="accordion-button"
            type="button"
            [class.collapsed]="!isRouteActive('my-cards')"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour"
            aria-expanded="false"
            aria-controls="flush-collapseFour"
            [routerLink]="['my-cards']"

          >
         Your Payment Cards
          </button>
        </h2>
        <div
          id="flush-collapseFour"
          class="accordion-collapse collapse"
          [class.show]="isRouteActive('my-cards')"
          aria-labelledby="flush-headingFour"
          data-bs-parent="#accordionFlushExample"
        >
        <app-my-cards-page></app-my-cards-page>
      </div>
      </div>
      <div class="">
        <h2 class="accordion-header" id="flush-headingFive">
          <button
            class="accordion-button"
            type="button"
            [class.collapsed]="!isRouteActive('my-favorite-products')"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFive"
            aria-expanded="false"
            aria-controls="flush-collapseFive"
            [routerLink]="['my-favorite-products']"
          >
          Favourite Products
          </button>
        </h2>
        <div
          id="flush-collapseFive"
          class="accordion-collapse collapse"
          [class.show]="isRouteActive('/my-favorite-products')"
          aria-labelledby="flush-headingFive"
          data-bs-parent="#accordionFlushExample"
        >
        <ea-favourite-products-page></ea-favourite-products-page>
      </div>
      </div>
      <div class="">
        <h2 class="accordion-header" id="flush-headingSix">
          <button
            class="accordion-button"
            type="button"
            [class.collapsed]="!isRouteActive('charities')"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSix"
            aria-expanded="false"
            aria-controls="flush-collapseSix"
            [routerLink]="['charities']"
          >
        Preferred Charity
          </button>
        </h2>
        <div
          id="flush-collapseSix"
          class="accordion-collapse collapse"
          [class.show]="isRouteActive('charities')"
          aria-labelledby="flush-headingSix"
          data-bs-parent="#accordionFlushExample"
        >
        <app-charity-page></app-charity-page>
      </div>
      </div>
      <div class="">
        <h2 class="accordion-header" id="flush-headingSeven">
          <button
            class="accordion-button"
            type="button"
            [class.collapsed]="!isRouteActive('change-password')"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSeven"
            aria-expanded="false"
            aria-controls="flush-collapseSeven"
            [routerLink]="['change-password']"
          >
            Change Password
          </button>
        </h2>
        <div
          id="flush-collapseSeven"
          class="accordion-collapse collapse"
          [class.show]="isRouteActive('change-password')"
          aria-labelledby="flush-headingSeven"
          data-bs-parent="#accordionFlushExample"
        >
        <div class="mt-3">
          <ea-reset-password-page></ea-reset-password-page>
        </div>        </div>
      </div>
      <div class="">
        <h2 class="accordion-header" id="flush-headingEight">
          <button
            class="accordion-button"
            type="button"
            [class.collapsed]="!isRouteActive('logout')"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseEight"
            aria-expanded="false"
            aria-controls="flush-collapseEight"
            (click)=" logout()"
          >
           Log Out
          </button>
        </h2>
        <div
          id="flush-collapseEight"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingEight"
          data-bs-parent="#accordionFlushExample"
        >
          <button
            class="ea-button ea-button-primary mt-2 w-100"
            (click)="logout()"
          >
            Log out
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--  End mobile-->

  <!--  <div class="account container">-->
  <!--    <div class="header pt-5 pb-5">-->
  <!--      <h1 class="font-bold">My Account</h1>-->
  <!--    </div>-->
  <!--    <div class="row ps-3 pe-3">-->
  <!--      <div class="col-xs-12 col-sm-6 col-lg-4">-->
  <!--        <a [routerLink]="'account-details'" class="card-border focus-outline">-->
  <!--          <div class="card bg-theme-surface text-theme-on-surface">-->
  <!--            <h2 class="font-bold">My Details</h2>-->
  <!--            <p class="text-theme-on-surface">-->
  <!--              Edit your personal and login details.-->
  <!--            </p>-->
  <!--          </div>-->
  <!--        </a>-->
  <!--      </div>-->
  <!--      <div class="col-xs-12 col-sm-6 col-lg-4">-->
  <!--        <a [routerLink]="'addresses'" class="card-border focus-outline">-->
  <!--          <div class="card bg-theme-surface text-theme-on-surface">-->
  <!--            <h2 class="font-bold">My Addresses</h2>-->
  <!--            <p class="text-theme-on-surface">View and manage your addresses.</p>-->
  <!--          </div>-->
  <!--        </a>-->
  <!--      </div>-->
  <!--      <div class="col-xs-12 col-sm-6 col-lg-4">-->
  <!--        <a [routerLink]="'my-cards'" class="card-border focus-outline">-->
  <!--          <div class="card bg-theme-surface text-theme-on-surface">-->
  <!--            <h2 class="font-bold">My Cards</h2>-->
  <!--            <p class="text-theme-on-surface">View and manage your cards.</p>-->
  <!--          </div>-->
  <!--        </a>-->
  <!--      </div>-->
  <!--      <div class="col-xs-12 col-sm-6 col-lg-4">-->
  <!--        <a [routerLink]="'orders'" class="card-border focus-outline">-->
  <!--          <div class="card bg-theme-surface text-theme-on-surface">-->
  <!--            <h2 class="font-bold">My Orders</h2>-->
  <!--            <p class="text-theme-on-surface">-->
  <!--              View, manage or return your orders.-->
  <!--            </p>-->
  <!--          </div>-->
  <!--        </a>-->
  <!--      </div>-->
  <!--      <div class="col-xs-12 col-sm-6 col-lg-4">-->
  <!--        <a-->
  <!--          [routerLink]="'my-favorite-products'"-->
  <!--          class="card-border focus-outline"-->
  <!--        >-->
  <!--          <div class="card bg-theme-surface text-theme-on-surface">-->
  <!--            <h2 class="font-bold">-->
  <!--              My Favourite Products-->
  <!--              <span class="like">-->
  <!--                <mat-icon>favorite</mat-icon> {{ this.countProducts }}-->
  <!--              </span>-->
  <!--            </h2>-->
  <!--            <p class="text-theme-on-surface">View Favourite Products.</p>-->
  <!--          </div>-->
  <!--        </a>-->
  <!--      </div>-->

  <!--      <div class="col-xs-12 col-sm-6 col-lg-4">-->
  <!--        <a [routerLink]="'charities'" class="card-border focus-outline">-->
  <!--          <div class="card bg-theme-surface text-theme-on-surface">-->
  <!--            <h2 class="font-bold">Charities</h2>-->
  <!--            <p class="text-theme-on-surface">View Charities.</p>-->
  <!--          </div>-->
  <!--        </a>-->
  <!--      </div>-->
  <!--      <div class="col-xs-12 col-sm-6 col-lg-4">-->
  <!--        <div-->
  <!--          class="p-0 card-border focus-outline logout-button"-->
  <!--          (click)="logout()"-->
  <!--        >-->
  <!--          <div class="card bg-theme-surface text-theme-on-surface">-->
  <!--            <h2 class="font-bold text-left">Logout</h2>-->
  <!--            <p class="text-left text-theme-on-surface">-->
  <!--              Log out of your account.-->
  <!--            </p>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</section>
