import { Pipe, PipeTransform } from '@angular/core';
import { Subscription, skip } from 'rxjs';
import { FilteredImageService } from 'src/app/services/shared/filter-image/filtered-image.service';

@Pipe({ name: 'getImageFilter', standalone: true, pure: false })
export class FilterImagePipe implements PipeTransform {
  value: any = '';

  sub: Subscription | undefined;

  constructor(private filterImageService: FilteredImageService) {}

  transform(value: string) {
    const filter = this.filterImageService.searchFilter.value;
    value = value.replace('/image/upload', '');

    if (!this.sub) {
      this.sub = this.filterImageService.searchFilter
        .pipe(skip(0))
        .subscribe((style: string) => {
          if (style) {
            this.updateValue(value, style);
          }
        });
    }

    this.value = this.getImage(value, filter);
    return this.value;
  }

  updateValue(key: string, filter: string): void {
    this.value = this.getImage(key, filter);
  }

  getImage(imageUrl: string, filter?: string): string {
    const base = 'https://res.cloudinary.com/revcascade/';

    return imageUrl.replace(base, `${base}${filter}`);
  }
  /**
   * Clean any existing subscription to change events
   */
  private _dispose(): void {
    if (typeof this.sub !== 'undefined') {
      this.sub.unsubscribe();
      this.sub = undefined;
    }
  }

  ngOnDestroy(): void {
    this._dispose();
  }
}
