<footer>
  <div class="bg-black subscribe-form py-5">
    <div class="container text-white">
      <h1 class="pb-3 text-center font-bold">Let’s stay in touch</h1>
      <form
        [formGroup]="newsLetterForm"
        data-synerise="newsletter"
        (submit)="submit($event)"
        class="ea-form bg-none border-none p-0 mb-3"
      >
        <p class="text-white text-center pb-4">
          Be the first to know about new products, offers and brands.
        </p>
        <div class="form-group">
          <div class="row d-flex flex-row align-items-center justify-center">
            <label class="m-1"  for="email">Email address</label>
            <div class="col-12 col-sm-9 mt-2 pe-2">
       
              <input
                type="email"
                class="ea-input w-100"
                id="email"
                formControlName="email"
                placeholder="example : johnsmith@email.com"
                alt="email input"
              />
            </div>
            <div class="col-12 col-sm-3 mt-1">
              <input
                [disabled]="!newsLetterForm.valid"
                type="submit"
                title="Subscribe"
                [ngClass]=" !newsLetterForm.valid ? 'ea-button ea-button-primary-inactive w-100' : 'ea-button ea-button-primary w-100'"
                value="Subscribe"
                alt="subscribe"
              />
            </div>

          </div>

        </div>

        <div class="newsLetterCheckbox form-group form-check mt-4 mb-4">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="newsletterAgreement"
            value=""
            id="agreement"
            checked
            alt="newsletter agreement checkboxes"
          />
          <label class="form-check-label" for="agreement">
            I agree to receive communications from EnableAll and consent to the
            storage of my data according to our privacy policy
          </label>
        </div>
        <p class="text-white">You can unsubscribe at any time. See our privacy policy for more info on how we use your data</p>

      </form>
      <div class="col-sm-12 col-md-5 col-lg-4 mb-5" *ngIf="this.isSubmit">
        <h2 class="font-bold mb-4">Thank You</h2>
        <p class="mb-4">A member of our team will be in touch shortly.</p>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="pt-5">
      <div class="row">
        <div
          class="col-12 col-sm-4 col-md-3 col-lg-3 text-start"
        >
          <span class="pb-3 font-bold">About EnableAll</span>
          <br />
          <ul class="p-0 mt-2">
            <li>
              <a [routerLink]="['about-us']" class="focus-outline"
                >About us </a
              >
            </li>
            <li>
              <a [routerLink]="['our-story']" class="focus-outline"
                >Our story</a
              >
            </li>
            <li>
              <a [routerLink]="['our-values']" class="focus-outline">
                Our values
              </a>
            </li>
            <li>
              <a
                [routerLink]="['accessibility-statement']"
                class="focus-outline"
              >
                Accessibility statement
              </a>
            </li>
            <li>
              <a [routerLink]="['contact-us']" class="focus-outline"
                >Contact us</a
              >
            </li>
          </ul>
        </div>
        <div
          class="col-12 col-sm-4 col-md-3 col-lg-3 text-start"
        >
          <span class="pb-3 font-bold">Purchasing on EnableAll</span>
          <br />
          <ul class="p-0 mt-2">
            <li>
              <a [routerLink]="['delivery-policy']" class="focus-outline"
                >Delivery</a
              >
            </li>

            <li>
              <a [routerLink]="['refunds-and-exchanges']" class="focus-outline"
                >Refunds & exchanges</a
              >
            </li>
          </ul>
        </div>
        <div
          class="col-12 col-sm-4 col-md-3 col-lg-3 text-start"
        >
          <span class="pb-3 font-bold">Selling on EnableAll</span>
          <br />
          <ul class="p-0 mt-2">
            <li>
              <a title="seller" 
                 aria-label="seller" 
                 routerLink="/seller"
                 class="focus-outline"
              >
                Sell on EnableAll
              </a>
            </li>

            <li>
              <a class="focus-outline">Help and support</a>
            </li>
            <li>
              <a class="focus-outline">Frequently asked questions</a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
          <img class="mx-auto" alt="EnableAll logo" src="assets/images/new-logos/EnableAll-2colour.svg" />
        </div>
      </div>
      <div class="row copyright mt-5">
        <div class="col-12 col-sm-6 text-start">
          <div class="list-inline p-0 m-0">
            <span class="list-inline-item">
              <a [routerLink]="['privacy-policy']" class="focus-outline"
                >Privacy policy</a
              >
            </span>
            |
            <span class="list-inline-item">
              <a [routerLink]="['web-usage-policy']" class="focus-outline"
                >Web usage policy</a
              >
            </span>
            |
            <span class="list-inline-item">
              <a [routerLink]="['customer-terms']" class="focus-outline"
                >Customer terms</a
              >
            </span>
          </div>
        </div>
        <div class="col-12 col-sm-6 d-flex align-items-center justify-content-md-end justify-content-center">
          <p class="text-center text-sm-end">
            &copy; 2023 EnableAll Limited - All rights reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
