<div
  class="container p-sm-3 p-md-5 bg-theme-surface text-theme-on-surface mb-3 rounded font-title mb-4"
>
  <div class="row">
    <div
      *ngIf="address"
      class="p-2 card bg-theme-surface text-theme-on-surface col-6"
    >
      <h2 class="font-bold mb-3">
        {{ address.additionalAttributes.nickName }}
      </h2>
      <p>{{ address.first }} {{ address.last }}</p>

      <p class="address-line">
        {{ transform(address.addressLine1) }}, @if(address.addressLine2){
        <br /><label *ngIf="address.addressLine2"
          >{{ transform(address.addressLine2) }},</label
        >
        } @if(address.addressLine3){
        <br />
        <label>{{ transform(address.addressLine3) }},</label>
        }
        <br />
        {{ transform(address.city) }}
        ,

        <br />{{ transform(address.county) }} <br />{{ address.postalCode }}
        <br />
        @if(address.country.toUpperCase() != 'GB'){
        {{ address.country.toUpperCase() }}
        } @else{ UK }
      </p>
      <p>{{ address.phone.number }}</p>
    </div>

    <div class="col-6 p-2">
      <div class="col-12 row">
        <div class="col-12 col-sm-6">
          <button
            (click)="editsAddressEvent()"
            class="font-title"
            style="font-size: 18px"
          >
            <u><mat-icon>arrow_drop_down</mat-icon> Edit</u>
          </button>
        </div>
        <div class="col-12 col-sm-6">
          <button
            (click)="deleteAddress(address.addressId)"
            class=""
            style="font-size: 18px"
          >
            <u
              ><span><mat-icon>close</mat-icon> Remove</span></u
            >
          </button>
        </div>
      </div>

      <div class="mt-4 col-12 col-lg-6">
        <div
          class="d-flex flex-col align-items-center flex-wrap justify-content-start my-3"
        >
          <input
            class="form-radio-input mx-auto mx-sm-0"
            type="radio"
            name="billing{{ address.addressId }}"
            id="billing{{ address.addressId }}"
            [(ngModel)]="address.additionalAttributes.isBillingAddress"
            [value]="true"
            style="width: 30px; height: 30px"
            (click)="toggleDefault(address, true)"
          />
          <label
            for="billing{{ address.addressId }}"
            class="px-2 text-center mx-auto mx-sm-0"
          >
            Default Billing
          </label>
        </div>
        <div
          class="d-flex flex-col align-items-center flex-wrap justify-content-start my-3"
        >
          <input
            class="form-radio-input mx-auto mx-sm-0"
            type="radio"
            name="shipping{{ address.addressId }}"
            id="shipping{{ address.addressId }}"
            [(ngModel)]="address.additionalAttributes.isShippingAddress"
            [value]="true"
            style="width: 30px; height: 30px"
            (click)="toggleDefault(address, false)"
          />
          <label
            for="shipping{{ address.addressId }}"
            class="px-2 text-center mx-auto mx-sm-0"
          >
            Default Delivery
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
