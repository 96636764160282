import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderStepsComponent } from '../../components/order-steps/order-steps.component';
import { LoginPageComponent } from '../../../auth/pages';
import { LoginFormComponent } from '../../../auth/components/login-form/login-form.component';
import { Router, RouterOutlet } from '@angular/router';
import { CartTotalsComponent } from '../../components/cart-totals';
import { map, Observable } from 'rxjs';
import { CartFacade } from '../../+state';
import { BaseComponent } from '@ea/components';
import { CartResponse } from '@ea/cart';
import { InfoCardComponent } from '../../components/info-card/info-card.component';
import { MatIcon } from '@angular/material/icon';
import { CartCreateAccountComponent } from '../cart-create-account/cart-create-account.component';
import { SubtotalMobileComponent } from '../../components/subtotal-mobile/subtotal-mobile.component';

@Component({
  selector: 'ea-cart-auth',
  standalone: true,
  imports: [
    CommonModule,
    OrderStepsComponent,
    LoginPageComponent,
    LoginFormComponent,
    RouterOutlet,
    LoginFormComponent,
    CartTotalsComponent,
    InfoCardComponent,
    MatIcon,
    CartCreateAccountComponent,
    SubtotalMobileComponent,
  ],
  templateUrl: './cart-auth.component.html',
  styleUrl: './cart-auth.component.scss',
})
export class CartAuthComponent extends BaseComponent {
  constructor(
    private router: Router,
    private facade: CartFacade,
  ) {
    super();
  }
  openLogin: boolean = false;
  openSignUp: boolean = false;
  cart$?: Observable<CartResponse>;
  total$: any;
  totalToPay: number = 0;
  ngOnInit(): void {
    this.cart$ = this.facade.cart$;
    this.total$ = this.cart$.pipe(
      map((cart) => {
        return (
          cart.items?.reduce((acc, item) => {
            this.totalToPay = acc + item.totalPrice.amount;
            return acc + item.totalPrice.amount;
          }, 0) ?? 0
        );
      }),
    );
  }
  goToLogin() {
    this.openLogin = !this.openLogin;
    this.openSignUp = false;
    // this.openSignUp = false;
    // this.router.navigate([`/cart/auth/login`]);
  }

  goToSignUp() {
    this.openLogin = false;
    this.openSignUp = !this.openSignUp;
  }
  cancel() {
    this.openLogin = false;
    this.openSignUp = false;
  }
}
