import { CartResponse, getDefaultCart } from '../models';

export interface CartState {
  isLoading: boolean;
  isLoaded: boolean;
  cart: CartResponse;
}

export const INITIAL_STATE: CartState = {
  isLoading: false,
  isLoaded: false,
  cart: getDefaultCart(),
};

export type CartItemUpdatePayload = {
  itemId: string;
  quantity: number;
  lineItemId: number;
};
