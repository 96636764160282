import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  RouterLink,
  RouterOutlet,
  Router,
  ActivatedRoute,
} from '@angular/router';

import { IBreadcrumb } from '@ea/models';
import { BehaviorSubject } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { AccountNavComponent } from '../../components/account-nav/account-nav.component';
import { AccountDetailsPageComponent } from '../account-details-page/account-details-page.component';
import { OrderPageComponent } from '../order-page/order-page.component';
import { AddressesPageComponent } from '../addresses-page/addresses-page.component';
import { MyCardsPageComponent } from '../my-cards-page/my-cards-page.component';
import { ProductliksPageComponent } from '../productLikes-page/productliks-page.component';
import { CharityPageComponent } from '../charity-page/charity-page.component';
import { ResetPasswordPageComponent } from '../../../auth/pages';
import { AuthFacade } from '@ea/auth';
import { OrderDetailsPageComponent } from '../order-details-page/order-details-page.component';
import { ProductLikeService } from '../../../../services/shared/ProductLiks/productLikes.service';

@Component({
  selector: 'ea-account-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgFor,
    NgIf,
    MatIconModule,
    RouterOutlet,
    AccountNavComponent,
    AccountDetailsPageComponent,
    OrderPageComponent,
    AddressesPageComponent,
    MyCardsPageComponent,
    ProductliksPageComponent,
    CharityPageComponent,
    ResetPasswordPageComponent,
    OrderDetailsPageComponent,
  ],
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent implements OnInit {
  breadcrumbs$: BehaviorSubject<IBreadcrumb[] | any>;
  orderId: any;
  countProducts = 0;
  constructor(
    private auth: AuthFacade,
    private router: Router,
    private route: ActivatedRoute,
    private productLikesService: ProductLikeService,
  ) {
    this.breadcrumbs$ = new BehaviorSubject([]);
  }
  goToOrderDetails(event: any) {
    this.orderId = event;
  }
  ngOnInit() {
    this.orderId = this.route.snapshot.params['orderId'];
    if (this.isRouteActive('/order-details')) {
      this.orderId =
        this.router.url.split('/')[this.router.url.split('/').length - 1];
    }
    const breadcrumbs = [
      {
        label: 'My Account',
        url: '/',
      },
      {
        label: 'My Details',
        url: '/account-details',
      },
      {
        label: 'My Addresses',
        url: '/addresses',
      },
    ];
    this.breadcrumbs$.next(breadcrumbs);
    this.productLikesService.getProductLiks().subscribe((x: any) => {
      if (x) {
        this.countProducts = x.length;
      }
    });
  }

  isRouteActive(route: string): boolean {
    return this.router.url.includes(route);
  }

  logout() {
    this.auth.logout('');
  }
}
