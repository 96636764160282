<section>
  <div class="container">
    <div *ngIf="!this.isBusy && skus.length > 0">
      <h1 class="d-none d-md-flex justify-start align-items-center">
        <mat-icon class="like me-2">favorite</mat-icon>Favourite products
      </h1>
      <ea-products [isLikePages]="true" [productslikes]="skus"></ea-products>
    </div>
  </div>
</section>
<div *ngIf="skus.length == 0">
  <section>
    <div class="container">
      <div class="my-products pt-3 pb-5">
        <div class="row">
          <ng-container *ngIf="skus.length == 0">
            <p>You have no Favourite product</p>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</div>
