<div *ngIf="pageArr.length > 1" class="d-flex justify-content-center mt-4">
  <div class="pagination focus-outline">
    <div class="d-flex flex-row align-content-center">
      <div class="p-0">
        <button
          class="pagination-btn font-title focus-ring"
          (click)="previousPage()"
          tabindex="0"
          aria-label ="Go back"
          [disabled]="currentPage === 1"
        >
          <span class="material-icons"> chevron_left </span>
        </button>
      </div>

      <div class="d-flex flex-row align-items-center flex-wrap p-0">
        <ng-container *ngFor="let page of pageArr; let i = index">
          <!-- Less than or equal to 10 pages -->
          <ng-container *ngIf="pageArr.length <= 10">
            <button
              class="pagination-btn font-title focus-ring"
              [class.active]="page === currentPage"
              (click)="goToPage(page)"
              tabindex="0"
            >
              {{ page }}
            </button>
          </ng-container>

          <!-- More than 10 pages -->
          <ng-container *ngIf="pageArr.length > 10">
            <!-- First page -->
            <ng-container *ngIf="i == 0">
              <button
                class="pagination-btn font-title focus-ring"
                [class.active]="page === currentPage"
                (click)="goToPage(page)"
                tabindex="0"
              >
                {{ page }}
              </button>
              <!--  next page-->
              <div *ngIf="currentPage == 1">
                <button
                  class="pagination-btn font-title focus-ring"
                  (click)="goToPage(page + 1)"
                  tabindex="0"
                >
                  {{ page + 1 }}
                </button>
                <!--  next next page-->
                <button
                  class="pagination-btn font-title focus-ring"
                  (click)="goToPage(page + 2)"
                  tabindex="0"
                >
                  {{ page + 2 }}
                </button>

                <span>...</span>
              </div>
            </ng-container>

            <!-- Middle pages around current page -->
            <ng-container *ngIf="i >= 1 && i <= pageArr.length - 2">
              <ng-container *ngIf="page == currentPage">
                <span *ngIf="currentPage > 3">...</span>
                <!--                previous page-->
                <button
                  *ngIf="currentPage != 2"
                  class="pagination-btn font-title focus-ring"
                  (click)="goToPage(page - 1)"
                  tabindex="0"
                >
                  {{ page - 1 }}
                </button>
                <!--                active page-->
                <button
                  class="pagination-btn font-title focus-ring"
                  [class.active]="page === currentPage"
                  (click)="goToPage(page)"
                  tabindex="0"
                >
                  {{ page }}
                </button>
                <!--                next page-->
                <button
                  *ngIf="currentPage + 1 != pageArr.length"
                  class="pagination-btn font-title focus-ring"
                  (click)="goToPage(page + 1)"
                  tabindex="0"
                >
                  {{ page + 1 }}
                </button>
                <span *ngIf="currentPage + 1 < pageArr.length - 1">...</span>
              </ng-container>
            </ng-container>

            <!-- Last page -->
            <ng-container *ngIf="i == pageArr.length - 1">
              <!--                previous page-->
              <div *ngIf="currentPage == pageArr.length">
                <span>...</span>
                <button
                  class="pagination-btn font-title focus-ring"
                  (click)="goToPage(page - 1)"
                  tabindex="0"
                >
                  {{ page - 1 }}
                </button>
              </div>

              <button
                class="pagination-btn font-title focus-ring"
                [class.active]="page === currentPage"
                (click)="goToPage(page)"
                tabindex="0"
              >
                {{ page }}
              </button>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <div class="p-0">
        <button
          class="pagination-btn focus-ring"
          (click)="nextPage()"
          [disabled]="currentPage === totalPages"
          tabindex="0"
          aria-label ="Go forward"
        >
          <span class="material-icons"> chevron_right </span>
        </button>
      </div>
    </div>
  </div>
</div>
