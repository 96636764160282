import { Directive, Input, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

@Directive()
export class BaseComponent implements OnDestroy {
  subscriptions: Array<Subscription> = [];
  @Input() isLoading = false;
  isButtonsDisabled$ = new BehaviorSubject<boolean>(false);

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
  toggleDisable(isDisabled?: boolean) {
    this.isButtonsDisabled$.next(
      isDisabled != undefined ? isDisabled : !this.isButtonsDisabled$.value
    );
  }
}
