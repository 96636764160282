import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  static toLabel(str: string) {
    let finalStr = str[0].toUpperCase() + str.slice(1);
    return finalStr;
  }
}
