<header>
  <!--MOBILE NAV-->
  <nav
    *ngIf="!readMode"
    class="navbar mobile-menu navbar-expand-lg mobile pb-0"
  >
    <div class="container-fluid ">
      <div class="d-flex justify-content-between align-items-center flex-nowrap w-100">
        <a
          [routerLink]="'/'"
          class="navbar-brand masthead-logo"
          [ngClass]="{ mobile: showSmallLogo }"
          aria-label="home page"
        ></a>
        <div class="d-flex">
          <ea-auth-links></ea-auth-links>
          <div class="menu-btn-container" [ngClass]="{ showTotal: showTotal }">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
              <div *ngIf="showTotal">
              <span class="total-cart-amount" *ngIf="cart$ | async as cart"
              >Total: <br /><span class="font-bold">{{
                  cart.totalAmount | currency: cart.currency
                }}</span>
              </span>
              </div>
            </button>
            <span class="icon-tag">Menu</span>
          </div>
        </div>
      </div>


      <div class="collapse navbar-collapse pt-2" id="navbarNav">
        <ng-container>
          <ul class="navbar-nav">
            <!--            <ea-auth-links></ea-auth-links>-->
            <li
              class="nav-item bg-light-gray border-top-gray-1px ps-2"
              *ngFor="let category of categories"
            >
              <a
                title="{{ category.name }}"
                [attr.aria-label]="category.name"
                routerLink="/category/0/{{ category.id }}"
                [queryParams]="{ via: 'nav' }"
                routerLinkActive="active"
                class="nav-link"
                data-bs-target="#navbarNav"
                data-bs-toggle="collapse"
              >
                {{ category.name }}
              </a>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </nav>
  <div *ngIf="showStep" class="step-container w-100 py-2 px-4">
    <button
      class="ea-button my-2 w-100 justify-start"
      [ngClass]="stepData?.styleClass"
      (click)="scrollToStepContainer()"
    >
      <img
        *ngIf="stepData?.icon?.type == 'img'"
        [alt]="stepData?.icon?.alt"
        class="step-icon"
        [ngClass]="stepData?.icon?.class"
        [src]="'assets/images/icons/' + stepData?.icon?.name"
      />
      <span *ngIf="stepData?.icon?.type == 'icon'" class="material-icons">{{
        stepData?.icon?.name
      }}</span>

      {{ stepData?.btnText }}
    </button>
  </div>
  <!--end MOBILE MENU-->

  <div class="header-content desktop">
    <button
      class="a11y-link focus-ring px-5"
      tabindex="0"
      (click)="openA11yWidget()"
    >
      Accessibility Tools
    </button>
    <div class="row m-0 py-3">
      <div class="col-md-3">
        <div class="logo justify-content-center me-3 row">
          <a
            [routerLink]="'/'"
            class="focus-ring masthead-logo justify-content-center"
            [title]="logoTitle"
            aria-label="EnableAll Home"
          >
            <span class="visually-hidden">EnableAll Home</span>
          </a>
        </div>
      </div>
      <div class="col-md-5 h-container">
        <ea-search-bar></ea-search-bar>
      </div>

      <div class="col-md-4 h-container">
        <ea-auth-links></ea-auth-links>
      </div>
    </div>
  </div>
</header>
