<form
  [formGroup]="registerForm"
  class="rounded ea-form bg-theme-surface"
  (ngSubmit)="register()"
>
  <p class="text-theme-on-surface">
    If you don't have an account with EnableAll, please create one
    using the form below.
  </p>
  <div class="form-group mt-4">
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="form-label ea-label required" for="fistname"
        >First name</label
        >
        <input
          type="text"
          class="ea-input w-100 focus-ring"
          placeholder="First name"
          aria-label="First name"
          formControlName="firstName"
          id="fistname"
          alt="first name"
        />
      </div>
      <div class="col-12 col-sm-6">
        <label class="form-label ea-label required" for="lastname"
        >Last name</label
        >
        <input
          type="text"
          class="ea-input w-100 focus-ring"
          placeholder="Last name"
          aria-label="Last name"
          formControlName="lastName"
          id="lastname"
          alt="last name input"
        />
      </div>
    </div>
  </div>

  <div class="form-group mt-4">
    <label class="form-label ea-label required" for="emailAddress"
    >Email</label
    >
    <input
      type="email"
      class="ea-input w-100 focus-ring"
      id="emailAddress"
      placeholder="Enter email"
      formControlName="email"
      alt="email input"
    />
  </div>

  <div class="form-group mt-4">
    <label for="phone" class="ea-label required">Phone number</label>
    <br />
    <ngx-intl-tel-input
      class="ea-input w-100 focus-ring"
      [preferredCountries]="preferredCountries"
      [enableAutoCountrySelect]="true"
      [enablePlaceholder]="true"
      [searchCountryFlag]="true"
      [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]"
      [selectFirstCountry]="false"
      [selectedCountryISO]="CountryISO.UnitedKingdom"
      [maxLength]="15"
      [phoneValidation]="true"
      [separateDialCode]="separateDialCode"
      [numberFormat]="PhoneNumberFormat.National"
      name="phone"
      id="phone"
      formControlName="phone"
    >
    </ngx-intl-tel-input>
  </div>

  <div class="form-group mt-4">
    <label class="form-label ea-label required" for="password"
    >Password</label
    >
    <br />
    <div class="input-group py-0">
      <input
        [type]="passwordVisible ? 'text' : 'password'"
        formControlName="password"
        placeholder="Password"
        class="ea-input form-control-flex focus-ring"
        id="password"
        alt="password input"
      />
      <div class="input-group-text">
        <button
          type="button"
          class="btn btn-dark m-0 p-0 focus-ring"
          mat-icon-button
          matSuffix
          (click)="togglePasswordVisibility()"
        >
                    <span class="material-icons">{{
                        passwordVisible ? "visibility" : "visibility_off"
                      }}</span>
        </button>
      </div>
    </div>
    <small class="text-theme-on-surface" style="color:#B3002F;">
      Password must be at least 8 characters and contain the following:
      <br>
      <ul class="mt-3">
        <li> • 1 uppercase letter</li>
        <li> • 1 number</li>
        <li> • 1 of these special characters : {{'@$%*?&#-_.'}} </li>

      </ul>
    </small>

    <br />
    <input
      type="checkbox"
      class="me-2 form-check-input focus-ring"
      id="checkboxNews"
      alt="news checkbox"
      checked
    />
    <label class="form-label" for="checkboxNews">
      Please send me emails with news, updates and offers
    </label>

    <button
      *ngIf="!fromCartPage"
      type="submit"
      value="CREATE ACCOUNT"
      class="ea-button ea-button-primary col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2 focus-ring"
      [disabled]="!registerForm.valid"
    >
      <strong>Create account</strong>
    </button>
  </div>
</form>
