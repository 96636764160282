<!--Desktop view-->
<div
  class="d-none d-md-flex flex-col flex-md-row align-items-center justify-content-between my-2"
>
  <button
    class="ea-button my-2"
    [ngClass]="{
      'ea-button-secondary': steps.Bag === 'active',
      'ea-button-stroked': steps.Bag === 'default',
      'ea-button-done': steps.Bag === 'done'
    }"
    (click)="goToPage('cart')"
  >
    <span *ngIf="steps.Bag === 'done'" class="material-icons">done</span>
    <img
      *ngIf="steps.Bag === 'active'"
      alt="bag icon"
      class="bag-icon"
      src="assets/images/icons/bag-white.svg"
    />
    <img
      *ngIf="steps.Bag === 'default'"
      alt="bag icon"
      class="bag-icon"
      src="assets/images/icons/bag.svg"
    />
    Bag
  </button>
  <hr class="horizontal d-none d-md-block" />
  <button
    *ngIf="!(isAuthenticated$ | async)"
    class="ea-button my-2"
    [ngClass]="{
      'ea-button-secondary': steps.Auth === 'active',
      'ea-button-stroked': steps.Auth === 'default',
      'ea-button-done': steps.Auth === 'done'
    }"
    (click)="goToPage('cart/auth')"
  >
    <span class="material-icons">person</span>Log in
    <br />
  </button>

  <hr
    *ngIf="!(isAuthenticated$ | async)"
    class="horizontal d-none d-md-block"
  />

  <button
    class="ea-button my-2"
    [ngClass]="{
      'ea-button-secondary': steps.Delivery === 'active',
      'ea-button-stroked': steps.Delivery === 'default',
      'ea-button-done': steps.Delivery === 'done'
    }"
    (click)="goToPage('checkout')"
    [disabled]="!(isAuthenticated$ | async)"
  >
    <span *ngIf="steps.Delivery === 'done'" class="material-icons">done</span>

    <img
      *ngIf="steps.Delivery === 'default'"
      alt="delivery icon"
      class="delivery-icon"
      src="assets/images/icons/delivery.svg"
    />
    <img
      *ngIf="steps.Delivery === 'active'"
      alt="delivery icon"
      class="delivery-icon"
      src="assets/images/icons/delivery-active.svg"
    />

    Delivery
  </button>
  <hr class="horizontal d-none d-md-block" />
  <button
    class="ea-button"
    [ngClass]="{
      'ea-button-secondary': steps.Payment === 'active',
      'ea-button-stroked':
        steps.Payment === 'default' || steps.Payment === 'disabled',
      'ea-button-done': steps.Payment === 'done'
    }"
    [disabled]="steps.Payment === 'disabled' || !(isAuthenticated$ | async) || isNoAddress"
    (click)="goToPage('payment')"
  >
    <span class="material-icons">lock</span><span>Secure&nbsp;payment</span>
  </button>
</div>
<!--Mobile view-->
<div
  class="d-flex d-md-none flex-col flex-md-row align-items-center justify-content-between my-2 m-4"
>
  <button
    class="ea-button my-2"
    *ngIf="step != 'step2' && step != 'Payment'"
    [ngClass]="{
      'ea-button-secondary': steps.Bag === 'active',
      'ea-button-stroked': steps.Bag === 'default',
      'ea-button-done': steps.Bag === 'done'
    }"
  >
    <span *ngIf="steps.Bag === 'done'" class="material-icons">done</span>
    <img
      *ngIf="steps.Bag === 'active'"
      alt="bag icon"
      class="bag-icon"
      src="assets/images/icons/bag-white.svg"
    />
    <img
      *ngIf="steps.Bag === 'default'"
      alt="bag icon"
      class="bag-icon"
      src="assets/images/icons/bag.svg"
    />
    Bag
  </button>
 @if( step != 'Auth'){
  <button
    *ngIf="step != 'Payment' && step != 'Bag'"
    class="ea-button my-2"
    [ngClass]="{
      'ea-button-secondary': steps.Delivery === 'active',
      'ea-button-stroked': steps.Delivery === 'default',
      'ea-button-done': step == 'step2'
    }"
    (click)="goToPage('checkout')"
    [disabled]="!(isAuthenticated$ | async)"
  >
    <span *ngIf="step == 'step2'" class="material-icons">done</span>

    <img
      *ngIf="steps.Delivery === 'default'"
      alt="delivery icon"
      class="delivery-icon"
      src="assets/images/icons/delivery.svg"
    />
    <img
      *ngIf="step != 'step2'"
      alt="delivery icon"
      class="delivery-icon"
      src="assets/images/icons/delivery-active.svg"
    />

    Step 1- Delivery address
  </button>

  <button
    *ngIf="step == 'step2' || step == 'Payment'"
    class="ea-button my-2"
    [ngClass]="{
      'ea-button-secondary': steps.Delivery === 'active',
      'ea-button-stroked': steps.Delivery === 'default',
      'ea-button-done': steps.Delivery === 'done'
    }"
    (click)="goToPage('checkout')"
    [disabled]="!(isAuthenticated$ | async)"
  >
    <span *ngIf="steps.Delivery === 'done'" class="material-icons">done</span>

    <img
      *ngIf="steps.Delivery === 'default'"
      alt="delivery icon"
      class="delivery-icon"
      src="assets/images/icons/delivery.svg"
    />
    <img
      *ngIf="steps.Delivery === 'active'"
      alt="delivery icon"
      class="delivery-icon"
      src="assets/images/icons/delivery-active.svg"
    />

    Step 2- Delivery options
  </button>
  <button
    *ngIf="step == 'Payment'"
    class="ea-button my-2"
    [ngClass]="{
      'ea-button-secondary': steps.Payment === 'active',
      'ea-button-stroked':
        steps.Payment === 'default' || steps.Payment === 'disabled',
      'ea-button-done': steps.Payment === 'done'
    }"
    [disabled]="steps.Payment === 'disabled' || !(isAuthenticated$ | async) ||  isNoAddress"
    (click)="goToPage('payment')"
  >
    <span class="material-icons">lock</span> Step 3- Secure payment
  </button>
}
@else{
  <button
    *ngIf="!(isAuthenticated$ | async)"
    class="ea-button my-2"
    [ngClass]="{
      'ea-button-secondary': steps.Auth === 'active',
      'ea-button-stroked': steps.Auth === 'default',
      'ea-button-done': steps.Auth === 'done'
    }"
    (click)="goToPage('cart/auth')"
  >
    <span class="material-icons">person</span>Log in
    <br />
  </button>
}
</div>
