import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME, CartFacade, cartReducer } from './+state';
import { CartRoutingModule } from './cart-routing.module';
import { CartService } from './services';

@NgModule({
  declarations: [],
  providers: [CartFacade, CartService],
  imports: [
    CartRoutingModule,
    StoreModule.forFeature(FEATURE_NAME, cartReducer),
  ],
})
export class CartModule {}
