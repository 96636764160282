<ng-container *ngIf="items.length">
  <div class="container">
    <nav>
      <ol class="pt-2" itemscope itemtype="http://schema.org/BreadcrumbList">
        <ng-container *ngFor="let item of items; index as i; let last = last">
          <li
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
          >
          @if(item.name != 'undefined'){
          @if(i == items.length -1 && items.length > 1)
          {
            <a
              [attr.aria-current]="last ? true : null"
              class="underline text-base focus-outline"
              [routerLink]="item.link"
              [queryParams]="{ root: items[i - 1].id , via:'nav'}"
              itemprop="item"
            >
              <span itemprop="name"> {{ item.name }}</span>
            </a>
          }
          @else{
          
            <a
            [attr.aria-current]="last ? true : null"
            class="underline text-base focus-outline"
            [routerLink]="item.link"
            [queryParams]="{  via:'nav'}"
            itemprop="item"
          >
            <span itemprop="name"> {{ item.name  }}</span>
          </a>
        }
          }
            <meta itemprop="position" content="{{ i + 1 }}" />
            <span
              *ngIf="!last"
              class="material-icons no-underline align-middle"
            >
              chevron_right
            </span>
          </li>
        </ng-container>
      </ol>
    </nav>
  </div>
</ng-container>
