import { Pipe, PipeTransform } from '@angular/core';
import { Attribute } from '../models';

@Pipe({ name: 'attribute', standalone: true })
export class AttributePipe implements PipeTransform {
  transform(value: { attributes?: Attribute[] }, name: string) {
    return (
      value.attributes?.find((attribute) => attribute.name === name)?.value ||
      ''
    );
  }
}
