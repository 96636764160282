import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartPageComponent, CheckoutPageComponent } from './pages';
import { CartAuthComponent } from './pages/cart-auth/cart-auth.component';
import { LoginFormComponent } from '../auth/components/login-form/login-form.component';
import { CartCreateAccountComponent } from './pages/cart-create-account/cart-create-account.component';

const routes: Routes = [
  {
    path: 'cart',
    title: 'Basket | EnableAll',
    children: [
      { path: '', component: CartPageComponent },
      {
        path: 'auth',
        component: CartAuthComponent,
        children: [
          { path: 'login', component: LoginFormComponent },
          { path: 'create-account', component: CartCreateAccountComponent },
        ],
      },
    ],
  },
  {
    path: 'checkout',
    title: 'Checkout | EnableAll',
    component: CheckoutPageComponent,
  },
  {
    path: 'payment',
    title: 'Payment | EnableAll',
    loadComponent: () =>
      import('../cart/pages/payment/payment-page.component').then(
        (x) => x.PaymentPageComponent,
      ),
  },
  {
    path: 'cart-login',
    title: 'Payment | EnableAll',
    loadComponent: () =>
      import('../cart/pages/payment/payment-page.component').then(
        (x) => x.PaymentPageComponent,
      ),
  },
  {
    path: 'OrderConfirmation',
    title: 'Payment | EnableAll',
    loadComponent: () =>
      import('../cart/components/order-thankyou/order-thankyou.component').then(
        (x) => x.OrderThankyouComponent,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CartRoutingModule {}
