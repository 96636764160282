import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared';
import { LocalStorageService, ToastService } from '@ea/services';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';
import { FormFieldComponent } from '../../../../components/form-field/form-field.component';
import { MatIconModule } from '@angular/material/icon';
import { CharityService } from 'src/app/services/shared/charityService/charity.service';
import { CharityData } from 'src/app/models/CharityData ';
import { CharityChooseComponent } from 'src/app/modules/cart/components/charity-choose/charity-choose.component';
import { fail } from 'assert';
import { Toast } from '@ea/models';
import { ToastComponent } from 'src/app/components/toast/toast.component';

@Component({
  selector: 'app-charity-page',
  standalone: true,
  templateUrl: './charity-page.component.html',
  styleUrls: ['./charity-page.component.scss'],
  imports: [
    CommonModule,
    RouterLink,
    NgFor,
    NgIf,
    SharedModule,
    NgxSkeletonLoaderModule,
    FormFieldComponent,
    MatIconModule,
    FormsModule,
    CharityChooseComponent,
    ToastComponent
  ],
})
export class CharityPageComponent implements OnInit {
  loader: boolean = true;
  selected: CharityData | undefined;
  options: CharityData[] = [];
  selectedDedisability : string ='';
  disability:any;
  charities : any
  customeId : string ='';
  constructor(
    private charityService: CharityService,
    public storage: LocalStorageService,
    private toasterService: ToastService
  ) {}
  toast? : Toast;
  closeToast(){
    this.toast = undefined;
  }

  ngOnInit() {

    this.customeId =   this.storage.getItem('customId');
      this.charityService
      .getProfileDropDownCheckout(this.storage.getItem('customId'))
      .subscribe((charities) => {
        if (charities) {
          this.options = charities;
          this.selected =
            charities.find((charity) => charity.isUserDefault) ? charities.find((charity) => charity.isUserDefault) : charities.find((charity) => charity.isDefault) || charities[0];
            this.selectedDedisability = this.selected!.areaofFocusNames;
            this.disability = [...new Set(charities.map(y => y.areaofFocusNames))];
            this.charities = this.options.filter(x => x.areaofFocusNames == this.selectedDedisability);
            this.loader =false;
        }
      });
  }

  changeDisability(selectedDedisability : any){
    this.charities = this.options.filter(x => x.areaofFocusNames == selectedDedisability)
  }
  onOptionsChangedEvent(event: any) {
    this.charityService
      .insertDefaultCharity(this.storage.getItem('customId'), event.charityId)
      .subscribe((x) => {
        this.toast  ={
          message: 'Preferred charity set successfully',
          duration: 6000 + Math.ceil('message'.split(' ').length / 120) * 100,
          type: 'success'
        };
      });
  }
}
