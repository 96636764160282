import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';
import { ProductLikeRequest } from 'src/app/models/productDetails.model';
import environment from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ProductLike } from '@ea/models';
@Injectable({
  providedIn: 'root',
})
export class ProductLikeService {
  baseUrl = '/ProductLikes';
  constructor(private api: ApiService) {}

  triggerProductLike(request: ProductLikeRequest) {
    return this.api.post(
      `${this.baseUrl}?&region=${environment.stripe.country}`,
      request
    );
  }

  getProductLiks(): Observable<ProductLike[]> {
    return this.api.get(
      `${this.baseUrl}?&region=${environment.stripe.country}`
    );
  }
}
