import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, output, Output } from '@angular/core';
import { selectAuthState } from '@ea/auth';
import { BaseComponent } from '@ea/components';
import { AddressService } from '@ea/services';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { AddressComponent } from '../address-component/address.component';
import { FormsModule } from '@angular/forms';
import { IResponseAddress } from '@ea/models';
import environment from '../../../../../environments/environment';
import { Router, RouterLink } from '@angular/router';
import { SelectedAddressComponent } from '../selected-address/selected-address.component';

@Component({
  selector: 'ea-checkout-addresses',
  standalone: true,
  imports: [CommonModule, FormsModule,RouterLink,SelectedAddressComponent],
  templateUrl: './checkout-addresses.component.html',
  styleUrls: ['./checkout-addresses.component.scss'],
})
export class CheckoutAddressesComponent extends BaseComponent {
  addresses: any[] = [];
  selectedAddress?: IResponseAddress;
  @Output() changes = new EventEmitter(true);
  @Output() goToDeleverystep = new EventEmitter(true);
  @Input() isBulling : boolean =false;
  @Input() bullingAddress? : any;
  @Input() newBillingAddress? :any;
  @Output() useThisAddressAsBilling = new EventEmitter(true);
  isLoaded : boolean = false;
  public get availableAddresses() {
    return this.addresses;
  }

  useAsBillingAddress(){
    this.useThisAddressAsBilling.emit();
  }
  goToDelevery(){
    this.goToDeleverystep.emit()
  }
  checkCountryAvailable(address: any) {
    if (environment.base === '') {
      return false; //enable all addresses for dev and local envs
    }

    const lowerCaseCountry = address.country.toLowerCase();
    switch (lowerCaseCountry) {
      case 'united kingdom':
      case 'uk':
          return environment.base !== 'uk';
      case 'gb':
        return environment.base !== 'uk'; //to disable not uk address for /uk envs
        case 'gbp':
          return environment.base !== 'uk';
      case 'gbr':
        return environment.base !== 'uk';
      case 'usa':
        return environment.base !== 'us'; //to disable not usa address for /us envs

      default:
        return true;
    }
  }

  onAddressSelected(address: any) {
    if(address){
    if (!this.checkCountryAvailable(address)) {
      this.selectedAddress = address;
    }  
    this.changes.emit(address);
  }
  }

  constructor(private addressService: AddressService, private store: Store, private router : Router) {
    super();

    this.subscriptions.push(
      this.store
        .select(selectAuthState)
        .pipe(
          switchMap((state) =>
            this.addressService.getAddress(state.user.customId)
          )
        )
        .subscribe((addresses) => {
          if(addresses && addresses.length > 0){
          this.addresses = addresses;
          if(!this.isBulling){
          this.onAddressSelected(
       
            this.addresses.find((x) => x.additionalAttributes.isShippingAddress) ?this.addresses.find((x) => x.additionalAttributes.isShippingAddress)  ?? this.addresses[0]:   this.addresses[0]
          );
        }
        else{
          if(!this.bullingAddress && !this.newBillingAddress){
          this.onAddressSelected(
          this.addresses.find((x) => x.additionalAttributes.isBillingAddress) ?   this.addresses.find((x) => x.additionalAttributes.isBillingAddress)?? this.addresses[0] : this.addresses[0]
          );
        }
        else{
          if(this.newBillingAddress){
            this.onAddressSelected(
              this.addresses.find((x) => x.addressId ==this.newBillingAddress ) ?  this.addresses.find((x) => x.addressId ==this.newBillingAddress )?? this.addresses[0] : this.addresses[0]
              );
          }
          else{
          this.onAddressSelected(
            this.addresses.find((x) => x.addressId ==this.bullingAddress ) ?? this.addresses[0]
            );
          }
        }
        }
      }
        this.isLoaded =true;
        })
    );
  }
}
