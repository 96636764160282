import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthFacade, AuthService } from '@ea/auth';
import {
  AddressService,
  LocalStorageService,
  OrderService,
} from '@ea/services';
import { BaseComponent } from '@ea/components';
import { MatIcon } from '@angular/material/icon';
import { ProductLikeService } from '../../../../services/shared/ProductLiks/productLikes.service';
import { switchMap } from 'rxjs';
import { StripeService } from 'src/app/services/shared/Stripe/stripe.Service';
declare var freshpaint: any;
@Component({
  selector: 'ea-account-nav',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, MatIcon],
  templateUrl: './account-nav.component.html',
  styleUrl: './account-nav.component.scss',
})
export class AccountNavComponent extends BaseComponent implements OnInit {
  countProducts: number = 0;
  countAddresses: number = 0;
  orderCount: number = 0;
  cardsNum: number = 0;
  user$: any;
  constructor(
    private auth: AuthFacade,
    private authService: AuthService,
    private storage: LocalStorageService,
    private router: Router,
    private productLikesService: ProductLikeService,
    private orderService: OrderService,
    private facade: AuthFacade,
    private stripeService: StripeService,
    private addressService: AddressService,
  ) {
    super();
    this.user$ = this.facade.user$;
  }
  ngOnInit() {
    this.productLikesService.getProductLiks().subscribe((x: any) => {
      if (x) {
        this.countProducts = x.length;
      }
      this.subscriptions.push(
        this.user$
          .pipe(
            switchMap((user: any) => {
              return this.orderService.getOrderByCustomerId(user.customId);
            }),
          )
          .subscribe((response: any) => {
            this.orderCount = response.count;
          }),
      );
    });

    const sub = this.stripeService
      .GetUserCards(this.storage.getItem('customId'))
      .subscribe((x) => {
        this.cardsNum = x.length;
      });

    const customId = this.storage.getItem('customId');
    this.addressService.getAddress(customId).subscribe((response) => {
      this.countAddresses = response.length;
    });
  }

  logout() {
    freshpaint.track("click",{
      Page_type :"Account",
      Page_name :"Account ",
      Click_name: "Button",
      Click_value :"log out"
    });
    this.auth.logout('');
  }
  changePassword() {
    freshpaint.track("click",{
      Page_type :"Account",
      Page_name :"Account ",
      Click_name: "Button",
      Click_value :"Change password"
    });
    this.toggleDisable(true);
    this.authService
      .chanePasswordAuthUset(this.storage.getItem('customId'))
      .subscribe({
        next: (x) => {
          this.router.navigate(['account/change-password/' + x.token]);
        },
        error: () => {
          this.toggleDisable(false);
        },
      });
  }
}
